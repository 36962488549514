import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftArrowIcon from "../assets/Images/CommonImages/GoLeft.png";
import PrintPurchaseOrder from "../assets/Images/CommonImages/Print.png";
import DocDownloadImg from "../assets/Images/CommonImages/DocDownload.png";
import BreadCrumbHeader from "../components/Header/BreadCrumbHeader";
import CommaSeprator from "../components/CommaSeprator";
import NumberToWord from "../components/NumberToWord";
import { getOrderSummary } from "../redux/action/OrderSummaryActions";
import BxiCoin from "../assets/Images/CommonImages/BXIToken.svg";
import { getCompanyById } from "../redux/action/CompanyActions";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import axios from "axios";

const MediaTaxInvoice = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const downloadRef = useRef(null);

  const [OrderData, setOrderData] = useState();
  const [Fulldata, setFullData] = useState();
  const [InvoiceData, setInvoiceData] = useState();
  const [MemberShipData, setMemberShipData] = useState();
  const [SellerWebsiteData, setSellerWebsiteData] = useState();
  const [usehubType, setUserHubType] = useState("");
  useEffect(() => {
    const storedVariable = localStorage.getItem("userhubtype");
    setUserHubType(storedVariable);
  }, []);
  async function getOrderDetailsById() {
    await axios.get(`order/get_order_by_id/${id}`).then((res) => {
      setFullData(res?.data);
      setOrderData(res?.data?.TaxInvoice);
      setInvoiceData(res?.data?.InvoiceData);
    });
  }

  const GetCompanyWebsiteByID = async () => {
    try {
      const response = await axios.get(
        `company/get_company/${Fulldata?.SellerCompanyId}`,
        {
          withCredentials: true,
        }
      );
      setSellerWebsiteData(response?.data?.website);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getCompanyById(OrderData?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  let storeDataIds = [];
  let TotalQuantity = 0;

  let totatlTaxableAmount = 0;

  OrderData?.ProductData?.map((item) => {
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;

    totatlTaxableAmount +=
      item.DiscountedPrice *
      item?.TimelineToBought *
      item.ProductQuantity *
      item?.BoughtSeconds;
  });

  useEffect(() => {
    getOrderDetailsById();
  }, []);

  useEffect(() => {
    GetCompanyWebsiteByID();
  }, [Fulldata]);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  let AdcostTotalTaxableAmount = 0;
  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;
  let totaladditionalcostGst = 0;
  let Totalcount = 0;

  OrderData?.ProductData?.map((item) => {
    item?.AdditionCost?.AdditionCostArrayData?.map((res) => {
      AdcostTotalTaxableAmount += res?.TotalTaxableAmount;

      totaladditionalcostGst +=
        (res?.TotalTaxableAmount * res?.AdCostGST) / 100;
    });
  });

  const GetTimeline = (timeline, boughtDates) => {
    let result = [];
    if (timeline === "Month") {
      const months = Math.ceil(boughtDates.length / 30);
      for (let i = 0; i < months; i++) {
        const startIndex = i * 30;
        const endIndex = Math.min((i + 1) * 30, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Week") {
      const weeks = Math.ceil(boughtDates.length / 7);
      for (let i = 0; i < weeks; i++) {
        const startIndex = i * 7;
        const endIndex = Math.min((i + 1) * 7, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Day") {
      result = boughtDates.map((date) => ({
        startDate: date,
        endDate: date,
      }));
    } else if (timeline === "Years") {
      const years = Math.ceil(boughtDates.length / 365);
      for (let i = 0; i < years; i++) {
        const startIndex = i * 365;
        const endIndex = Math.min((i + 1) * 365, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    }

    return result;
  };

  let BuyerId = OrderData?.BuyerDetails?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [OrderData]);

  let BxiCommision = OrderData?.CouponData?.BXICommission
    ? Number(OrderData?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      hsn: "996211",
      taxableValue:
        (Number(OrderData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(OrderData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let amountbeforeTax =
    ChargesTaxableValue + totatlTaxableAmount + AdcostTotalTaxableAmount;

  let totalTaxAmount =
    OrderData?.PITotals?.TotalGstAmount +
    totaladditionalcostGst +
    ChargesTaxableAmount;

  let invoiceTotalAmount = totalTaxAmount + amountbeforeTax;

  let tdsAmount = ((amountbeforeTax * 1) / 100).toFixed(2);
  let totalAmountWithTDS = Number(invoiceTotalAmount);

  return (
    <Box
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Tax Invoice"} PageName={"Tax Invoice"} />

      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          mx: "auto",
          borderRadius: "17px",
          pb: "40px",
        }}
        elevation={1}
      >
        <Paper
          sx={{
            width: "95%",
            mx: "auto",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
          elevation={0}
        >
          <Box
            component="img"
            src={LeftArrowIcon}
            sx={{ width: "25px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Box
            sx={{
              display: "flex",
              width: "60px",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              src={PrintPurchaseOrder}
              sx={{ width: "22px", height: "auto", cursor: "pointer" }}
              onClick={handlePrint}
            />
            <Box
              component="img"
              src={DocDownloadImg}
              sx={{ width: "21px", height: "auto", cursor: "pointer" }}
              // onClick={handleDownloadClick}
              onClick={handlePrint}
            />
          </Box>
        </Paper>
        <Box ref={componentRef}>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
            // ref={printRef}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
              ref={downloadRef}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src={OrderData?.SellerDetails?.SellerCompanyLogo}
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderData?.SellerDetails?.SellerCompanyName}
                </Typography>
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    textAlign: "center",
                    fontSize: "11px",
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderData?.SellerDetails?.Address?.AddressLine}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  Tel:{OrderData?.SellerDetails?.SellerCompanyContact}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  GSTIN:{OrderData?.SellerDetails?.GSTIN}
                </Typography>
              </Box>
              <Box
                sx={{
                  color: "#445FD2",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                  borderLeft: "1px solid #cdcdcd",
                }}
              >
                {SellerWebsiteData ? SellerWebsiteData : null}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: usehubType === "seller" ? "#c64091" : "#2261A2",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                {"Tax Invoice"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  Invoice Number: {OrderData?.FINumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  Invoice Date: {convertDate(OrderData?.createdAt)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
                borderTop: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  P.O. Date:{" "}
                  {new Date(OrderData?.createdAt).toLocaleDateString("en-IN")}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  P.O. Number: {OrderData?.PoNumber}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: usehubType === "seller" ? "#c64091" : "#2261A2",
                  borderRadius: "3px 0px 0px 3",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "10px",
                      color: "white",
                    }}
                  >
                    Bill to Party
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    Ship to Party
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody sx={{ p: 0 }}>
                      <TableRow sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer name :
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Address:{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.AddressLine}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State: </Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            position: "relative",
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.State}
                          </Typography>
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderData?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {Fulldata?.PurchaseOrderData?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderData?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Address:{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.AddressLine}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State:</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            position: "relative",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderData?.BuyerDetails?.Address?.State}
                          </Typography>{" "}
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderData?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {Fulldata?.PurchaseOrderData?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                <TableHead>
                  <TableRow sx={{ height: "20px" }}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography
                        sx={{
                          TableTextStyle,
                          fontSize: "13px",
                          lineHeight: "0px",
                          color: "#000",
                        }}
                      >
                        ONLINE
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography
                        sx={{
                          TableTextStyle,
                          fontSize: "13px",
                          lineHeight: "0px",
                          color: "#000",
                        }}
                      >
                        OFFLINE
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow
                    sx={{
                      height: "42px",
                      bgcolor: usehubType === "seller" ? "#c64091" : "#2261A2",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}> Sr. No.</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>
                        Product / Service Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        {" "}
                        HSN / SAC <br />
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Ad
                        <br /> (In Seconds)
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        {" "}
                        Timeline <br />( Day / Week <br />/ Months )
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        {" "}
                        Ad <br />
                        (In Days/ Week/
                        <br /> Month)
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Ad <br />
                        Multipler
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>QTY</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Rate</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>Taxable Value</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {OrderData?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;
                    Totalcount += 1;
                    return (
                      <React.Fragment key={idx}>
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {Totalcount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.HSN}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {row?.TotalSec && (
                              <Typography sx={TableBottomtext}>
                                {" "}
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? ""
                                  : "10"}
                                &nbsp;
                              </Typography>
                            )}
                          </TableCell>{" "}
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.ProductTypeId === "MediaOffline" ||
                              row?.ProductTypeId === "News Papers / Magazines"
                                ? ``
                                : `${row?.TimelineToBought} ${row?.Timeline}`}
                              &nbsp;
                            </Typography>
                          </TableCell>{" "}
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.ProductTypeId === "MediaOffline" ||
                              row?.ProductTypeId === "News Papers / Magazines"
                                ? `1 ${row?.Timeline}`
                                : ``}
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {row?.TotalSec && (
                              <Typography sx={TableBottomtext}>
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? `${row?.TimelineToBought}`
                                  : `${TotalSec}`}
                                &nbsp;
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.ProductQuantity} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.DiscountedPrice} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {/* Bought Dates */}
                        {GetTimeline(row?.Timeline, row?.BoughtDates).map(
                          (el, idx) => {
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {row.Timeline === "Day" ? (
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        textAlign: "left",
                                      }}
                                    >
                                      &nbsp;
                                      {idx + 1}).{" "}
                                      {new Date(el.startDate).toDateString()}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        textAlign: "left",
                                      }}
                                    >
                                      &nbsp;
                                      {idx + 1}).{" "}
                                      {new Date(el.startDate).toDateString()} to{" "}
                                      {new Date(el.endDate).toDateString()}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "13px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "14px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;Additional Cost
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  {/* Additional Costs */}
                  {OrderData?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;
                    return (
                      <React.Fragment key={idx}>
                        {row?.AdditionCost?.AdditionCostArrayData?.map(
                          (res, index) => {
                            Totalcount += 1;
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                                key={index}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {Totalcount}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    // px: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      fontSize: "13px",
                                      width: "300px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    &nbsp;{res?.ReasonOfCost} {"  "}({" "}
                                    {res?.AdCostApplicableOn === "PerUnit"
                                      ? "Per Unit"
                                      : "One Time"}{" "}
                                    ){" "}
                                    {res?.currencyType === "₹" ? (
                                      "₹"
                                    ) : (
                                      <img
                                        src={BxiCoin}
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                        }}
                                      />
                                    )}{" "}
                                    ({row?.ProductName}){" "}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  >
                                    {res?.AdCostHSN}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>{" "}
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>{" "}
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <>
                                        {row?.TotalSec && (
                                          <>
                                            {row?.ProductTypeId ===
                                              "MediaOffline" ||
                                            row?.ProductTypeId ===
                                              "News Papers / Magazines"
                                              ? `${row?.TimelineToBought}`
                                              : `${TotalSec}`}
                                          </>
                                        )}
                                      </>
                                    ) : null}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={row?.ProductQuantity}
                                      />
                                    ) : null}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.PriceWithoutGST}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}

                  {/* Charges Costs */}
                  {ChargesArray?.map((res, idx) => {
                    Totalcount += 1;
                    return (
                      <TableRow
                        sx={{
                          height: "25px",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography sx={TableBottomtext}>
                            {Totalcount}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontSize: "13px",
                              width: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color:
                                res?.name === "BXI Commission"
                                  ? "red"
                                  : "#000000",
                            }}
                          >
                            &nbsp;{res?.name}{" "}
                            {res?.name === "BXI Commission"
                              ? `@ ${res?.commission}%`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            {res?.hsn}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "13px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  {/* Total Costs */}
                  <TableRow
                    sx={{
                      height: "42px",
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={3}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        background:
                          usehubType === "seller" ? "#c64091" : "#2261A2",
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "white" }}>
                        Total amount before Tax
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={TotalQuantity} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        {/* <CommaSeprator
                          Price={
                            OrderData?.PITotals?.TotalProductCoins +
                            OrderData?.PITotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI +
                            OrderData?.PITotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee
                          }
                        /> */}
                        <CommaSeprator Price={amountbeforeTax.toFixed(2)} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Terms & Conditions
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      {" "}
                      <Typography sx={TableBottomtext}>
                        {" "}
                        &nbsp;&nbsp;Add: GST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;CGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {!OrderData?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount / 2} />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Payment 100% in advance
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;SGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {!OrderData?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount / 2} />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                      }}
                    ></TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;IGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {OrderData?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount} />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        // borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Total amount Payable (in words)
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Total GST Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={totalTaxAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        {OrderData?.PITotals ? (
                          <NumberToWord number={invoiceTotalAmount.toFixed()} />
                        ) : null}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Invoice Total Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={invoiceTotalAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          color:
                            usehubType === "seller" ? "#c64091" : "#4361ee",
                        }}
                      >
                        * Our Services are subject to TDS of 1% u/s 194-O of the
                        Income Tax act, 1961.
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>TDS @ 1%</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={tdsAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#4361ee",
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        Total Payable (Round off)
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={totalAmountWithTDS.toFixed()} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
            }}
          >
            <Table
              sx={{
                borderLeft: "1px solid #CDCDCD",
              }}
            >
              <TableBody>
                <TableRow
                  sx={{
                    height: "21px",
                    borderBottom: "2px solid #CDCDCD",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "180px",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          // bgcolor: "green",
                          height: "20px",
                          border: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                          }}
                        >
                          HSN / SAC
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "180px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          height: "20px",
                          border: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                          }}
                        >
                          Taxable Value
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "220px",
                      mx: "auto",
                    }}
                    align="center"
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "220px",
                          borderRight: "1px solid #CDCDCD",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                          }}
                        >
                          CGST Tax
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0,
                          height: "20px",
                          width: "25%",
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...TableBottomtext,
                          }}
                        >
                          Rate
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "25%",
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...TableBottomtext,
                          }}
                        >
                          Amount
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "220px",
                      mx: "auto",
                    }}
                    align="center"
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "220px",
                          borderRight: "1px solid #CDCDCD",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                          }}
                        >
                          SGST Tax
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0,
                          height: "20px",
                          width: "25%",
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...TableBottomtext,
                          }}
                        >
                          Rate
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "25%",
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...TableBottomtext,
                          }}
                        >
                          Amount
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "220px",
                      mx: "auto",
                    }}
                    align="center"
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "220px",
                          borderRight: "1px solid #CDCDCD",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                          }}
                        >
                          Integrated Tax
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0,
                          height: "20px",
                          width: "25%",
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...TableBottomtext,
                          }}
                        >
                          Rate
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "25%",
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            ...TableBottomtext,
                          }}
                        >
                          Amount
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "200px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "200px",
                          border: "none",
                          p: 0,
                          height: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                          }}
                        >
                          Total Tax Amount
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                </TableRow>

                {OrderData?.ProductData?.map((res, idx) => {
                  return (
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {res?.HSN}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                            px: 0,
                          }}
                        >
                          <TableCell
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={res?.TotalPriceWithoutGST}
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.GST / 2} />%
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={res?.TotalGSTAmount / 2}
                                />
                                &nbsp;
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.GST / 2} />%
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={res?.TotalGSTAmount / 2}
                                />
                                &nbsp;
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                            }}
                          >
                            {" "}
                            {OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.GST} /> %
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {" "}
                            {OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.TotalGSTAmount} />
                                &nbsp;
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableCell>

                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={res?.TotalGSTAmount} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {OrderData?.ProductData?.map((row, idx) => {
                  return row?.AdditionCost?.AdditionCostArrayData?.map(
                    (res, index) => {
                      let GstOfAdCost = Number(res?.AdCostGST);
                      return (
                        <TableRow
                          sx={{
                            height: "21px",
                          }}
                        >
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "180px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "180px",
                                  p: 0,
                                  height: "20px",
                                  border: "none",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {res?.AdCostHSN}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "180px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "180px",
                                  p: 0,
                                  height: "20px",
                                  border: "none",
                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.TotalTaxableAmount}
                                  />
                                  &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {!OrderData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.AdCostGST / 2} />
                                    %
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {!OrderData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={
                                          (res?.TotalTaxableAmount *
                                            (GstOfAdCost / 100)) /
                                          2
                                        }
                                      />
                                    ) : (
                                      <CommaSeprator
                                        Price={
                                          (res?.TotalTaxableAmount *
                                            (GstOfAdCost / 100)) /
                                          2
                                        }
                                      />
                                    )}
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {!OrderData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.AdCostGST / 2} />
                                    %
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {!OrderData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={
                                          (res?.TotalTaxableAmount *
                                            (GstOfAdCost / 100)) /
                                          2
                                        }
                                      />
                                    ) : (
                                      <CommaSeprator
                                        Price={
                                          (res?.TotalTaxableAmount *
                                            (GstOfAdCost / 100)) /
                                          2
                                        }
                                      />
                                    )}
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {OrderData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.AdCostGST} /> %
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {OrderData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={
                                          res?.TotalTaxableAmount *
                                          (GstOfAdCost / 100)
                                        }
                                      />
                                    ) : (
                                      <CommaSeprator
                                        Price={
                                          res?.TotalTaxableAmount *
                                          (GstOfAdCost / 100)
                                        }
                                      />
                                    )}
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>

                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "200px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "200px",
                                  border: "none",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {res?.AdCostApplicableOn === "PerUnit" ? (
                                    <CommaSeprator
                                      Price={
                                        res?.TotalTaxableAmount *
                                        (GstOfAdCost / 100)
                                      }
                                    />
                                  ) : (
                                    <CommaSeprator
                                      Price={
                                        res?.TotalTaxableAmount *
                                        (GstOfAdCost / 100)
                                      }
                                    />
                                  )}
                                  &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  );
                })}

                {ChargesArray?.map((res, idx) => {
                  return (
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {res?.hsn}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={res?.taxableValue} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.gst / 2} />%
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={res?.totalTaxAmount / 2}
                                />
                                &nbsp;
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.gst / 2} />%
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {" "}
                            {!OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={res?.totalTaxAmount / 2}
                                />
                                &nbsp;
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                            }}
                          >
                            {" "}
                            {OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.gst} /> %
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {" "}
                            {OrderData?.IsIGST ? (
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.totalTaxAmount} />
                                &nbsp;
                              </Typography>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableCell>

                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={res?.totalTaxAmount} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow
                  sx={{
                    height: "21px",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "180px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          height: "20px",
                          border: "none",
                        }}
                      >
                        <Typography sx={TableBottomtext}></Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "180px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          height: "20px",
                          border: "none",
                          textAlign: "right",
                        }}
                      >
                        <Typography sx={TableBottomtext}>&nbsp;</Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "220px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          height: "20px",
                          border: "none",
                          textAlign: "right",
                        }}
                      >
                        <Typography sx={TableBottomtext}>&nbsp;</Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "220px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          height: "20px",
                          border: "none",
                          textAlign: "right",
                        }}
                      >
                        <Typography sx={TableBottomtext}>&nbsp;</Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "220px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "180px",
                          p: 0,
                          height: "20px",
                          border: "none",
                          textAlign: "right",
                        }}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          Total GST Amount &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>

                  <TableCell
                    sx={{
                      p: 0,
                      maxWidth: "200px",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <TableRow
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{
                          width: "200px",
                          border: "none",
                          p: 0,
                          height: "20px",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator Price={totalTaxAmount} />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    rowSpan={1}
                    sx={tablecell}
                  >
                    &nbsp;
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                borderRight: "1px solid #CDCDCD",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        minWidth: "380px",
                        borderRight: "1px solid #CDCDCD",
                        bgcolor:
                          usehubType === "seller" ? "#c64091" : "#2261A2",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "#ffffff" }}>
                        Bank Details :{" "}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          width: "380px",
                          textAlign: "end",
                          fontSize: "12px",
                        }}
                      >
                        For Seller Company Name
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderLeft: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Account No. : {OrderData?.BankDetails?.AccountNumber}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={6}
                      sx={tablecell}
                    >
                      {/* <img
                          src={BXIStamp}
                          style={{
                            maxHeight: "160px",
                            height: "auto",
                            width: "auto",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderLeft: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        IFSC Code : {OrderData?.BankDetails?.IFSC}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderLeft: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        Branch Name : {OrderData?.BankDetails?.BranchName}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderLeft: "1px solid #CDCDCD",
                        borderBottom: "1px solid #CDCDCD",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Bank Name : {OrderData?.BankDetails?.IFSC?.slice(0, 4)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "1px solid #CDCDCD",
                        borderLeft: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={{ ...tablecell, borderLeft: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableBottomtext}>
                        Ceritified that the particulars given above are true and
                        correct
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "1px solid #CDCDCD",
                        borderLeft: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        This is a System Generated Invoice by BXI WORLD LLP
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MediaTaxInvoice;

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#000000",
  opacity: 1,
};

const TextLastStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  // lineHeight: "20px",
  color: "#000000",
  opacity: 1,
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
