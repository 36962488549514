import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdatePurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import { useFetchPurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import CheckboxIcon from "../../assets/Images/CommonImages/CheckboxIcon.svg";
import CheckedBoxIcon from "../../assets/Images/CommonImages/CheckedBoxIcon.svg";
import CheckedCheckboxIcon from "../../assets/Images/CommonImages/CheckedCheckboxIcon.svg";
import CloseIcon from "../../assets/Images/CommonImages/CloseIcon.svg";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import LeftArrowIcon from "../../assets/Images/CommonImages/GoLeft.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { getCompanyById } from "../../redux/action/CompanyActions";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import StateData from "../../utils/StateCityArray.json";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import axios from "axios";
import { useGetInvoiceByOrderSummary } from "../../Hooks/Invoices/useGetInvoiceByOrderSummary";

import { CircularProgress } from "@material-ui/core";
import NumberToWord from "../../components/NumberToWord";
import { MdKeyboardBackspace } from "react-icons/md";
import CommaSeprator from "../../components/CommaSeprator";
import { useReactToPrint } from "react-to-print";
import PageLoader from "../../components/LoadingButton/PageLoader";
import useUpdateMediaContent from "../../Hooks/MediaActions/useUpdateMediaContent";
import MediaContentUpload from "./MediaContentUpload";
import OrderProgressBar from "../../components/ProgressBar/OrderProgressBar";

const MediaPI = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [stateArray, setStateArray] = useState();
  const [Address, setAddress] = useState("");
  const [Area, setArea] = useState("");
  const [state, setState] = useState("");
  const [BuyerShippingAddress, setBuyerShippingAddress] = useState();
  const [CityArray, setCityArray] = useState();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTextarea, setOpenTextarea] = useState("");
  const [AvatarIcon, setAvatarIcon] = useState(null);
  const [MemberShipData, setMemberShipData] = useState();
  const [StoreOrderId, setStoreOrderId] = useState();
  const [SellerWebsiteData, setSellerWebsiteData] = useState();

  const {
    data: OrderSummarydata,
    isLoading: OrderSummaryDataLoading,
    error: DataError,
    refetch: InvoiceRefetch,
  } = useGetInvoiceByOrderSummary(id);

  const GetCompanyWebsiteByID = async () => {
    try {
      const response = await axios.get(
        `company/get_company/${OrderSummarydata?.SellerDetails?.SellerCompanyId}`,
        {
          withCredentials: true,
        }
      );
      setSellerWebsiteData(response?.data?.website);
    } catch (error) {
      console.error(error);
    }
  };

  async function GetOrderByInvoice() {
    await axios.get(`order/get_order_by_orderSummaryId/${id}`).then((res) => {
      setStoreOrderId(res?.data?.PurchaseOrderData);
    });
  }
  useEffect(() => {
    GetOrderByInvoice();
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [dataFromChild, setDataFromChild] = useState("");

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  useEffect(() => {
    setBuyerShippingAddress({
      PinCode: pincode,
      City: city,
      State: state,
      Address: Address,
    });
  }, [pincode, city, state, Address, Area]);

  useEffect(() => {
    dispatch(getCompanyById(OrderSummarydata?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  let storeDataIds = [];
  let TotalQuantity = 0;
  let totalAmount = 0;
  let totalPricePerUnit = 0;
  let totatlTaxableAmount = 0;
  let totalGST = 0;
  let totalAmountWithGST = 0;
  let totalAmountWithTax = 0;
  let totalCGSTAmount = 0;
  let totalIGSTPercentage = 0;
  let totalSGSTAmount = 0;
  let totalSGSTPercentage = 0;
  let totalCSTPerCentage = 0;
  let TotalGSTAmount = 0;
  let TotalAdCostINRGST = 0;
  let TotalAdCostINRWithoutGST = 0;
  let TotalAdCostBXIWithoutGST = 0;
  let TotalAdCostBXIGST = 0;
  let totaladditionalcostinrupee = 0;
  let totaladditionalcostinbxi = 0;
  let Totalcount = 0;

  OrderSummarydata?.ProductData?.map((item) => {
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
    TotalAdCostINRWithoutGST += item?.TotalAdCostInINR;
    TotalAdCostBXIWithoutGST += item?.TotalAdCostINBXI;
    TotalAdCostINRGST += item?.AdCostINRGST;
    TotalAdCostBXIGST += item?.AdCostBXIGST;
    totalAmount += item?.TotalMediaPrice;
    totalPricePerUnit += item.DiscountedPrice;
    totatlTaxableAmount +=
      item.DiscountedPrice *
      item?.TimelineToBought *
      item.ProductQuantity *
      item?.BoughtSeconds;
    totalGST += item.GST;
    totalAmountWithGST += (
      item?.DiscountedPrice *
      item?.ProductQuantity *
      (item?.GST / 100)
    )?.toFixed(2);
    totalAmountWithTax +=
      item?.TotalMediaPrice * (item?.GST / 100) + item?.TotalMediaPrice;

    totalCGSTAmount += (totalAmount * item?.GST) / 2 / 100;
    totalSGSTAmount += (totalAmount * item?.GST) / 2 / 100;
    totalSGSTPercentage += item.GST / 2;
    totalCSTPerCentage += item.GST / 2;
    totalIGSTPercentage += item.GST;
    TotalGSTAmount += (totalAmount * item?.GST) / 100;
  });

  let totaltaxvalue = (totatlTaxableAmount * totalGST) / 100;

  let TotalAdCost = 0;
  let TotalTaxableAmount = 0;
  let TotalAdCostGST = 0;
  let AdcostTotalTaxableAmount = 0;
  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;

  OrderSummarydata?.ProductData?.map((datah) => {
    datah?.AdditionCost?.AdditionCostArrayData?.map((res) => {
      AdcostTotalTaxableAmount += res?.TotalTaxableAmount;
      TotalAdCost += res?.TotalCost;
      TotalTaxableAmount += res?.AdCostWithoutGST;
      TotalAdCostGST += res?.AdCostGSTPrice;
      if (res?.AdCostCurrencyType === "BXITokens") {
        totaladditionalcostinbxi += res?.AdCostWithoutGST;
      } else {
        totaladditionalcostinrupee += res?.AdCostWithoutGST;
      }
    });
  });

  const GetTimeline = (timeline, boughtDates) => {
    let result = [];
    if (timeline === "Month") {
      const months = Math.ceil(boughtDates.length / 30);
      for (let i = 0; i < months; i++) {
        const startIndex = i * 30;
        const endIndex = Math.min((i + 1) * 30, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Week") {
      const weeks = Math.ceil(boughtDates.length / 7);
      for (let i = 0; i < weeks; i++) {
        const startIndex = i * 7;
        const endIndex = Math.min((i + 1) * 7, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Day") {
      result = boughtDates.map((date) => ({
        startDate: date,
        endDate: date,
      }));
    } else if (timeline === "Years") {
      const years = Math.ceil(boughtDates.length / 365);
      for (let i = 0; i < years; i++) {
        const startIndex = i * 365;
        const endIndex = Math.min((i + 1) * 365, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    }

    return result;
  };
  const {
    data: updatePurchaseOrderData,
    isLoading: updatePurchaseOrderLoading,
    error: updatePurchaseOrderError,
    mutate: updatePurchaseOrderMutate,
  } = useUpdatePurchaseOrder();

  const {
    data: fetchPurchaseOrder,
    isLoading: PurchaseOrderLoading,
    refetch: PurchaseOrderRefetch,
  } = useFetchPurchaseOrder(id);

  async function mutatePurchaseOrder() {
    PurchaseOrderRefetch();
    if (
      OrderSummarydata?.IsMedia &&
      !OrderSummarydata?.UploadContantFileUrl &&
      dataFromChild !== true
    ) {
      toast.error("Please upload media content", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      if (checked) {
        if (
          !BuyerShippingAddress?.PinCode ||
          !BuyerShippingAddress?.City ||
          !BuyerShippingAddress?.State ||
          !BuyerShippingAddress?.Address
        ) {
          toast.error("Please fill all the fields", {
            position: "top-center",
            autoClose: 2000,
          });
        } else {
          await updatePurchaseOrderMutate({
            status: "Accepted",
            OrderSummaryId: id,
            BuyerShippingAddress: BuyerShippingAddress
              ? BuyerShippingAddress
              : "",
          });
          PurchaseOrderRefetch();
        }
      } else {
        await updatePurchaseOrderMutate({
          status: "Accepted",
          OrderSummaryId: id,
          BuyerShippingAddress: "",
        });
        PurchaseOrderRefetch();
      }
    }
  }

  useEffect(() => {
    if (updatePurchaseOrderData?.status === "Accepted") {
      toast.success("Order Accepted", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/home/mediapilist");
      }, 2000);
    } else if (updatePurchaseOrderData?.status === "Rejected") {
      toast.error("Order Declined", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/home/mediapurchaseorder");
      }, 2000);
    }
  }, [dispatch, updatePurchaseOrderData]);

  const printRef = useRef();

  const downloadRef = useRef(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (stateArray) {
      let stateData = StateData?.filter((item) => item?.name === stateArray);
      setCityArray(stateData[0]?.data);
    }
  }, [stateArray]);

  const {
    data: mediaContentResponse,
    isLoading: mediaContentLoading,
    error: mediaContentError,
    mutate: mediaContentMutate,
  } = useUpdateMediaContent();

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  let AdCostInrTotal = 0;
  let AdCostBxiTotal = 0;
  let totaladditionalcostGst = 0;
  OrderSummarydata?.ProductData?.map((item) => {
    if (item?.AdditionCost.AdditionCostArrayData?.length > 0) {
      item?.AdditionCost.AdditionCostArrayData.forEach((item) => {
        if (item?.currencyType === "₹") {
          AdCostInrTotal += item?.GstPrice;
          totaladditionalcostinrupee += item?.TotalWithGst;
          totaladditionalcostGst +=
            (item?.TotalTaxableAmount * item?.AdCostGST) / 100;
        } else if (item?.currencyType === "BXITokens") {
          AdCostBxiTotal += item?.GstPrice;
          totaladditionalcostinbxi += item?.TotalWithGst;
          totaladditionalcostGst +=
            (item?.TotalTaxableAmount * item?.AdCostGST) / 100;
        }
      });
    }
  });

  let BuyerId = OrderSummarydata?.BuyerDetails?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
    GetCompanyWebsiteByID();
  }, [OrderSummarydata]);

  let BxiCommision = StoreOrderId?.CouponData?.BXICommission
    ? Number(StoreOrderId?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      hsn: "996211",
      taxableValue:
        (Number(OrderSummarydata?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(OrderSummarydata?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let amountbeforeTax =
    ChargesTaxableValue + totatlTaxableAmount + AdcostTotalTaxableAmount;

  let totalTaxAmount =
    OrderSummarydata?.PITotals?.TotalGstAmount +
    totaladditionalcostGst +
    ChargesTaxableAmount;

  let invoiceTotalAmount = totalTaxAmount + amountbeforeTax;

  if (OrderSummaryDataLoading) {
    return <PageLoader />;
  }
  return (
    <Box
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={props?.Title} />

      <OrderProgressBar type={"mediaProformaInvoice"} Data={props?.OrderId} />
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          mx: "auto",
          borderRadius: "17px",
          pb: "40px",
        }}
        elevation={1}
      >
        <Paper
          sx={{
            width: "95%",
            mx: "auto",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
          elevation={0}
        >
          <Box
            component="img"
            src={LeftArrowIcon}
            sx={{ width: "25px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Box
            sx={{
              display: "flex",
              width: "60px",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              src={PrintPurchaseOrder}
              sx={{ width: "22px", height: "auto", cursor: "pointer" }}
              onClick={handlePrint}
            />
            <Box
              component="img"
              src={DocDownloadImg}
              sx={{ width: "21px", height: "auto", cursor: "pointer" }}
              onClick={handlePrint}
            />
          </Box>
        </Paper>
        <Box ref={componentRef}>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
              ref={downloadRef}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src={OrderSummarydata?.SellerDetails?.SellerCompanyLogo}
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderSummarydata?.SellerDetails?.SellerCompanyName}
                </Typography>
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    textAlign: "center",
                    fontSize: "11px",
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderSummarydata?.SellerDetails?.Address?.AddressLine}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  Tel:{OrderSummarydata?.SellerDetails?.SellerCompanyContact}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  GSTIN:{OrderSummarydata?.SellerDetails?.GSTIN}
                </Typography>
              </Box>

              <Box
                sx={{
                  color: "#445FD2",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                  borderLeft: "1px solid #cdcdcd",
                }}
              >
                {SellerWebsiteData ? SellerWebsiteData : null}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: props?.SellerPage ? "#C64091" : "#2261A2",
                borderRadius: "0px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                {props?.PageName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  PI Number: {OrderSummarydata?.PINumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  PI Date: {convertDate(OrderSummarydata?.createdAt)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: props?.SellerPage ? "#C64091" : "#2261A2",
                  borderRadius: "3px 0px 0px 3",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "10px",
                      color: "white",
                    }}
                  >
                    Seller Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    Buyer Details
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody sx={{ p: 0 }}>
                      <TableRow sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Seller Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.SellerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> Address </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {
                              OrderSummarydata?.SellerDetails?.Address
                                ?.AddressLine
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State: </Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            position: "relative",
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.Address?.State}
                          </Typography>
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderSummarydata?.SellerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.Seller_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box sx={{ width: "100%", mx: "auto", p: 2 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderSummarydata?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> Address </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {
                              OrderSummarydata?.BuyerDetails?.Address
                                ?.AddressLine
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            position: "relative",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderSummarydata?.BuyerDetails?.Address?.State}
                          </Typography>{" "}
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderSummarydata?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
            {props?.SellerPage === true &&
            OrderSummarydata?.BuyerRequestedAddress?.Address !== "" &&
            !props?.IsMedia ? (
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "21px",
                    background: props?.SellerPage ? "#C64091" : "#2261A2",
                    borderRadius: "3px 0px 0px 3",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                      borderRight: "1px solid #F3F2F3",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        fontSize: "10px",
                        color: "white",
                      }}
                    >
                      Buyer Ship To / Delivery Location Details Below
                    </Typography>
                  </Box>
                </Box>

                <Table
                  sx={{
                    p: 0,
                    border: "1px solid #cdcdcd",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    borderRadius: "0px 0px 10px 10px",
                    width: "100%",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          State:{" "}
                          {OrderSummarydata?.BuyerRequestedAddress?.State}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          City: {OrderSummarydata?.BuyerRequestedAddress?.City}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0.5,
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          Pincode:{" "}
                          {OrderSummarydata?.BuyerRequestedAddress?.PinCode}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "auto",
                          maxWidth: "300px",
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          Address:{" "}
                          {OrderSummarydata?.BuyerRequestedAddress?.Address}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            ) : null}
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
              }}
            >
              <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                <TableHead>
                  <TableRow sx={{ height: "20px" }}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography
                        sx={{
                          TableTextStyle,
                          fontSize: "13px",
                          lineHeight: "0px",
                          color: "#000",
                        }}
                      >
                        ONLINE
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography
                        sx={{
                          TableTextStyle,
                          fontSize: "13px",
                          lineHeight: "0px",
                          color: "#000",
                        }}
                      >
                        OFFLINE
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow
                    sx={{
                      height: "42px",
                      bgcolor: props?.SellerPage ? "#C64091" : "#2261A2",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}> Sr. No.</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>
                        Product / Service Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        {" "}
                        HSN / SAC <br />
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Ad
                        <br /> (In Seconds)
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        {" "}
                        Timeline <br />( Day / Week <br />/ Months )
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        {" "}
                        Ad <br />
                        (In Days/ Week/
                        <br /> Month)
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Ad <br />
                        Multipler
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>QTY</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Rate</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>Taxable Value</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {OrderSummarydata?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;
                    Totalcount += 1;
                    return (
                      <React.Fragment key={idx}>
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {Totalcount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.HSN}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {row?.TotalSec && (
                              <Typography sx={TableBottomtext}>
                                {" "}
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? ""
                                  : "10"}
                                &nbsp;
                              </Typography>
                            )}
                          </TableCell>{" "}
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.ProductTypeId === "MediaOffline" ||
                              row?.ProductTypeId === "News Papers / Magazines"
                                ? ``
                                : `${row?.TimelineToBought} ${row?.Timeline}`}
                              &nbsp;
                            </Typography>
                          </TableCell>{" "}
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.ProductTypeId === "MediaOffline" ||
                              row?.ProductTypeId === "News Papers / Magazines"
                                ? `1 ${row?.Timeline}`
                                : ``}
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            {row?.TotalSec && (
                              <Typography sx={TableBottomtext}>
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? `${row?.TimelineToBought}`
                                  : `${TotalSec}`}
                                &nbsp;
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.ProductQuantity} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.DiscountedPrice} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {/* Bought Dates */}
                        {GetTimeline(row?.Timeline, row?.BoughtDates).map(
                          (el, idx) => {
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {row.Timeline === "Day" ? (
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        textAlign: "left",
                                      }}
                                    >
                                      &nbsp;
                                      {idx + 1}).{" "}
                                      {new Date(el.startDate).toDateString()}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        textAlign: "left",
                                      }}
                                    >
                                      &nbsp;
                                      {idx + 1}).{" "}
                                      {new Date(el.startDate).toDateString()} to{" "}
                                      {new Date(el.endDate).toDateString()}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "13px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "14px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;Additional Cost
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  {/* Additional Costs */}
                  {OrderSummarydata?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;
                    return (
                      <React.Fragment key={idx}>
                        {row?.AdditionCost?.AdditionCostArrayData?.map(
                          (res, index) => {
                            Totalcount += 1;
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                                key={index}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {Totalcount}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    // px: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      fontSize: "13px",
                                      width: "300px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    &nbsp;{res?.ReasonOfCost} {"  "}({" "}
                                    {res?.AdCostApplicableOn === "PerUnit"
                                      ? "Per Unit"
                                      : "One Time"}{" "}
                                    ){" "}
                                    {res?.currencyType === "₹" ? (
                                      "₹"
                                    ) : (
                                      <img
                                        src={BxiCoin}
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                        }}
                                      />
                                    )}{" "}
                                    ({row?.ProductName}){" "}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  >
                                    {res?.AdCostHSN}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>{" "}
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>{" "}
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <>
                                        {row?.TotalSec && (
                                          <>
                                            {row?.ProductTypeId ===
                                              "MediaOffline" ||
                                            row?.ProductTypeId ===
                                              "News Papers / Magazines"
                                              ? `${row?.TimelineToBought}`
                                              : `${TotalSec}`}
                                          </>
                                        )}
                                      </>
                                    ) : null}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={row?.ProductQuantity}
                                      />
                                    ) : null}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.PriceWithoutGST}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}

                  {/* Charges Costs */}
                  {ChargesArray?.map((res, idx) => {
                    Totalcount += 1;
                    return (
                      <TableRow
                        sx={{
                          height: "25px",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography sx={TableBottomtext}>
                            {Totalcount}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontSize: "13px",
                              width: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color:
                                res?.name === "BXI Commission"
                                  ? "red"
                                  : "#000000",
                            }}
                          >
                            &nbsp;{res?.name}{" "}
                            {res?.name === "BXI Commission"
                              ? `@ ${res?.commission}%`
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            {res?.hsn}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "13px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  {/* Total Costs */}
                  <TableRow
                    sx={{
                      height: "42px",
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={3}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        background: props?.SellerPage ? "#C64091" : "#2261A2",
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "white" }}>
                        Total amount before Tax
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>{" "}
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={TotalQuantity} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={amountbeforeTax.toFixed(2)} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Terms & Conditions
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      {" "}
                      <Typography sx={TableBottomtext}>
                        {" "}
                        &nbsp;&nbsp;Add: GST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;CGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {!OrderSummarydata?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount / 2} />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Payment 100% in advance
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;SGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {!OrderSummarydata?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount / 2} />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                      }}
                    ></TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;IGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {OrderSummarydata?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount} />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        // borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Total amount Payable (in words)
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Total GST Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={totalTaxAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        {OrderSummarydata?.PITotals ? (
                          <NumberToWord number={invoiceTotalAmount.toFixed()} />
                        ) : null}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Invoice Total Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={invoiceTotalAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: props?.SellerPage ? "#c64091" : "#4361ee",
                        }}
                      >
                        * Our Services are subject to TDS of 1% u/s 194-O of the
                        Income Tax act, 1961.
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        Total Payable (Round off)
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={invoiceTotalAmount.toFixed()} />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#4361ee",
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        {/* Total Payable (Round off) */}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}></Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "2px solid #CDCDCD",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}></Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}></Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table
                sx={{
                  borderLeft: "1px solid #CDCDCD",
                }}
              >
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderBottom: "2px solid #CDCDCD",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            // bgcolor: "green",
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            HSN / SAC
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            Taxable Value
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "220px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "220px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            CGST Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "220px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "220px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            SGST Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "220px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "220px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            Integrated Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            Total Tax Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>

                  {OrderSummarydata?.ProductData?.map((res, idx) => {
                    return (
                      <TableRow
                        sx={{
                          height: "21px",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                {res?.HSN}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                              px: 0,
                            }}
                          >
                            <TableCell
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                                textAlign: "right",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={res?.TotalPriceWithoutGST}
                                />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.GST / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.TotalGSTAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.GST / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.TotalGSTAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.GST} /> %
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.TotalGSTAmount} />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>

                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "200px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "200px",
                                border: "none",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.TotalGSTAmount} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {OrderSummarydata?.ProductData?.map((row, idx) => {
                    return row?.AdditionCost?.AdditionCostArrayData?.map(
                      (res, index) => {
                        let GstOfAdCost = Number(res?.AdCostGST);
                        return (
                          <TableRow
                            sx={{
                              height: "21px",
                            }}
                          >
                            <TableCell
                              sx={{
                                p: 0,
                                maxWidth: "180px",
                                mx: "auto",
                                borderRight: "1px solid #CDCDCD",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "180px",
                                    p: 0,
                                    height: "20px",
                                    border: "none",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostHSN}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                maxWidth: "180px",
                                mx: "auto",
                                borderRight: "1px solid #CDCDCD",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "right",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "180px",
                                    p: 0,
                                    height: "20px",
                                    border: "none",
                                    textAlign: "right",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                width: "220px",
                                mx: "auto",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "220px",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    width: "110px",
                                  }}
                                >
                                  {" "}
                                  {!OrderSummarydata?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator
                                        Price={res?.AdCostGST / 2}
                                      />
                                      %
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    width: "110px",
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                    px: 0,
                                  }}
                                >
                                  {" "}
                                  {!OrderSummarydata?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            (res?.TotalTaxableAmount *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            (res?.TotalTaxableAmount *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                width: "220px",
                                mx: "auto",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "220px",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    width: "110px",
                                  }}
                                >
                                  {" "}
                                  {!OrderSummarydata?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator
                                        Price={res?.AdCostGST / 2}
                                      />
                                      %
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    width: "110px",
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                    px: 0,
                                  }}
                                >
                                  {" "}
                                  {!OrderSummarydata?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            (res?.TotalTaxableAmount *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            (res?.TotalTaxableAmount *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                width: "220px",
                                mx: "auto",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "220px",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    width: "110px",
                                  }}
                                >
                                  {" "}
                                  {OrderSummarydata?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator Price={res?.AdCostGST} /> %
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    width: "110px",
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                    px: 0,
                                  }}
                                >
                                  {" "}
                                  {OrderSummarydata?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            res?.TotalTaxableAmount *
                                            (GstOfAdCost / 100)
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            res?.TotalTaxableAmount *
                                            (GstOfAdCost / 100)
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableCell>

                            <TableCell
                              sx={{
                                p: 0,
                                maxWidth: "200px",
                                mx: "auto",
                                borderRight: "1px solid #CDCDCD",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "right",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "200px",
                                    border: "none",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={
                                          res?.TotalTaxableAmount *
                                          (GstOfAdCost / 100)
                                        }
                                      />
                                    ) : (
                                      <CommaSeprator
                                        Price={
                                          res?.TotalTaxableAmount *
                                          (GstOfAdCost / 100)
                                        }
                                      />
                                    )}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    );
                  })}

                  {ChargesArray?.map((res, idx) => {
                    return (
                      <TableRow
                        sx={{
                          height: "21px",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                {res?.hsn}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                                textAlign: "right",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.taxableValue} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.gst / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.totalTaxAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.gst / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.totalTaxAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.gst} /> %
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {OrderSummarydata?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.totalTaxAmount} />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>

                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "200px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "200px",
                                border: "none",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.totalTaxAmount} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography sx={TableBottomtext}></Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={TableBottomtext}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "220px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={TableBottomtext}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "220px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={TableBottomtext}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "220px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography
                            sx={{ ...TableBottomtext, fontWeight: 600 }}
                          >
                            Total GST Amount &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                            textAlign: "right",
                            px: 0,
                          }}
                        >
                          <Typography
                            sx={{ ...TableBottomtext, fontWeight: 600 }}
                          >
                            <CommaSeprator Price={totalTaxAmount} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <img
                          src={BxiCoin}
                          style={{
                            width: "12px",
                            height: "auto",
                          }}
                        />{" "}
                        - This symbol denotes Barter Coin that can be used only
                        for transactions within the BXI marketplace
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        ₹ - This symbol denotes the Indian Rupee involved in the
                        Transaction
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Ceritified that the particulars given above are true and
                        correct
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        This is a System Generated Invoice by BXI WORLD LLP
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {!props?.SellerPage ? (
            <>
              {fetchPurchaseOrder?.UploadContantFileUrl &&
              fetchPurchaseOrder?.UploadContantFileUrl !== null ? null : (
                <MediaContentUpload
                  id={id}
                  sendDataToParent={handleDataFromChild}
                />
              )}
            </>
          ) : null}

          {(props?.SellerPage === true && !OrderSummarydata?.IsMedia) ||
          OrderSummarydata?.ProductData?.at(0)?.ProductTypeName ===
            "Media" ? null : (
            <Box sx={{ width: "95%", mx: "auto" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="checked"
                    color="primary"
                  />
                }
                label={
                  <span style={{ fontSize: "14px" }}>
                    Click here to add new delivery location
                  </span>
                }
              />
            </Box>
          )}
          {props?.SellerPage === true || OrderSummarydata?.IsMedia
            ? null
            : checked && (
                <React.Fragment>
                  <Box
                    sx={{
                      width: "95%",
                      mx: "auto",
                      height: "20px",
                      bgcolor: props?.SellerPage ? "#C64091" : "#2261A2",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography sx={TableTextStyle}>
                      Fill the Address Details : Ship To / Delivery Location
                      Details
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                      mx: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <label style={AddressInputTextStyle}>State</label>
                      <Select
                        sx={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "35px",
                          borderRadius: "5px",
                          border: "1px solid #cdcdcd",
                          outline: "none",
                          "&:focus": {
                            border: "1px solid #2261A2",
                          },
                        }}
                        onChange={(e) => {
                          setStateArray(e.target.value);
                          setState(e.target.value);
                        }}
                        name="state"
                        value={stateArray}
                        id="state"
                        required
                        style={AddressInputStyle}
                      >
                        {StateData?.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        ).map((res, index) => (
                          <MenuItem key={index} value={res?.name}>
                            {res?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <label style={AddressInputTextStyle}>City</label>
                      <Select
                        sx={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "35px",
                          borderRadius: "5px",
                          border: "1px solid #cdcdcd",
                          outline: "none",
                          "&:focus": {
                            border: "1px solid #2261A2",
                          },
                        }}
                        onChange={(e) => setCity(e.target.value)}
                        name="state"
                        value={CityArray}
                        id="state"
                        required
                        style={AddressInputStyle}
                      >
                        {CityArray?.map((res, index) => (
                          <MenuItem key={index} value={res}>
                            {res}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <label style={AddressInputTextStyle}>Pincode</label>
                      <input
                        type="text"
                        placeholder=" "
                        className="inp"
                        onChange={(e) => setPincode(e.target.value)}
                        required
                        style={{ ...AddressInputStyle, width: "250px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "400px",
                      }}
                    >
                      <label style={AddressInputTextStyle}>Address</label>
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setAddress(e.target.value)}
                        className="inp"
                        required
                        style={{ ...AddressInputStyle, width: "400px" }}
                      />
                    </Box>
                  </Box>
                </React.Fragment>
              )}
        </Box>
      </Box>

      {props?.SellerPage === true ? null : (
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "500px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            {fetchPurchaseOrder?.BuyerOrderStatus === "Pending" &&
            !PurchaseOrderLoading ? (
              <Box
                sx={ButtonStyleForAcceptAndReject}
                onClick={mutatePurchaseOrder}
              >
                Accept{" "}
              </Box>
            ) : fetchPurchaseOrder?.BuyerOrderStatus === "Accepted" &&
              !PurchaseOrderLoading ? (
              <Box
                sx={ButtonStyleForAcceptAndReject}
                onClick={() => navigate("/home/mediapilist")}
              >
                Next Step
              </Box>
            ) : fetchPurchaseOrder?.BuyerOrderStatus === "Rejected" &&
              !PurchaseOrderLoading ? (
              <Box sx={ButtonStyleForAcceptAndReject}>Order Rejected</Box>
            ) : (
              <CircularProgress size={20} color="inherit" />
            )}

            {fetchPurchaseOrder?.BuyerOrderStatus === "Rejected" ? (
              <Box
                sx={{
                  ...ButtonStyleForAcceptAndReject,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  bgcolor: "#fff",
                  color: "#2261A2",
                  border: "1px solid #2261A2",
                }}
                onClick={() => navigate("/home/cart")}
              >
                <MdKeyboardBackspace size={25} />
                Back to Cart
              </Box>
            ) : fetchPurchaseOrder?.BuyerOrderStatus === "Accepted" ? null : (
              <React.Fragment>
                <Box
                  sx={{
                    ...ButtonStyleForAcceptAndReject,
                    bgcolor: "#fff",
                    border: "1px solid #445FD2",
                    color: "#445FD2",
                  }}
                  onClick={() => setOpen(true)}
                >
                  Reject
                </Box>

                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(0%, 0%)",
                  }}
                >
                  <Box
                    sx={{
                      background: "#fff",
                      width: "350px",
                      height: openTextarea === "" ? "400px" : "480px",
                      p: 3,
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "16px",
                            color: "#6B7A99",
                          }}
                        >
                          Please select the reason of Rejection
                        </Typography>
                        <Box
                          component="img"
                          src={CloseIcon}
                          onClick={() => setOpen(false)}
                          sx={{
                            cursor: "pointer",
                          }}
                        ></Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "15px",
                        width: "100%",
                      }}
                    >
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "price" ? "#445FD2" : "#6B7A99",
                            border:
                              openTextarea == "price"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("price")}
                        >
                          <Box
                            sx={{
                              ...TextAndCheckBoxGroupStyle,
                              border: "none",
                            }}
                          >
                            {openTextarea == "price" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "price"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Price{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "price"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Price{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "price" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "deliverydate"
                                ? "#445FD2"
                                : "#6B7A99",
                            border:
                              openTextarea == "deliverydate"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("deliverydate")}
                        >
                          <Box sx={TextAndCheckBoxGroupStyle}>
                            {openTextarea == "deliverydate" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "deliverydate"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Delivery Date{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "deliverydate"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Delivery Date{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "deliverydate" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "quantity"
                                ? "#445FD2"
                                : "#6B7A99",
                            border:
                              openTextarea == "quantity"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("quantity")}
                        >
                          <Box sx={TextAndCheckBoxGroupStyle}>
                            {openTextarea == "quantity" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "quantity"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Quantity{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "quantity"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Quantity{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "quantity" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "other" ? "#445FD2" : "#6B7A99",
                            border:
                              openTextarea == "other"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("other")}
                        >
                          <Box sx={TextAndCheckBoxGroupStyle}>
                            {openTextarea == "other" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "other"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Other{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "other"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Other{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "other" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                              // InputProps={{ border: "none" }}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Button
                        onClick={() => setOpen(false)}
                        sx={{
                          width: "50%",
                          color: "#000",
                          textTransform: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          border: "1px solid #D0D5DD",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          width: "50%",
                          color: "#fff",
                          background: "#445FD2",
                          textTransform: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          "&:hover": {
                            background: "#445FD2",
                          },
                        }}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </React.Fragment>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MediaPI;

const AddressInputStyle = {
  width: "186px",
  height: "35px",
  background: "#F9F9F9",
  borderRadius: "6px",
  border: "none",
  paddingLeft: "10px",
};

const AddressInputTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "10px",
  color: "#6B7A99",
};

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "left",
  opacity: 1,
  color: "#000000",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  opacity: 1,
  color: "#000000",
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const AddressTextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#505050",
  opacity: 1,

  width: "300px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  opacity: 0.5,
  color: "#000000",
  opacity: 1,
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const RejectReasonBtnStyle = {
  width: "100%",
  mx: "auto",
  textTransform: "none",
  height: "40px",
  borderRadius: "6px",
  border: "1px solid #E4E7EC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ButtonGroupstyle = {
  width: "100%",
  border: "none",
};

const TextAndCheckBoxGroupStyle = {
  width: "100%",
  px: "10px",
  cursor: "pointer",
};

const TextAreaStyle = {
  width: "100%",
  mx: "auto",
  background: "#ECEFF1",
  color: "#C7C7CC",
  border: "1px solid lighgray",
  height: "100px",
  "& fieldset": { border: "none" },
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  my: 1,
};

const InputPropsStyle = {
  sx: {
    background: "#ECEFF1",
    fontFamily: "Poppins",
    color: "#445FD2",
    borderRadius: "10px",
    fontSize: "14px",
    width: "100%",
  },
};

const ReasonTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#6B7A99",
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
