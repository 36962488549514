import {
  BottomNavigation,
  Box,
  Button,
  Typography,
  ImageList,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { useEffectOnce } from "react-use";
import { useGetCompanyDetails } from "../../../../Hooks/Auth";
import useGetCompanyTypeData from "../../../../Hooks/CompanyData/useGetCompanyTypeData";
import DeleteIcon from "../../../../assets/Images/CommonImages/DeleteIcon.svg";
import UploadtoCloud from "../../../../assets/UploadtoCloud.svg";
import ToolTip from "../../../../components/ToolTip";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function TextileGoLive() {
  const navigate = useNavigate();
  const id = useParams().id;
  const [loader, setLOader] = useState(false);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const [storeImages, setStoreImages] = useState();
  const [formError, setFormError] = useState(false);
  const [ListPeriod, setListPeriod] = useState();
  const [ProductData, setProductData] = useState("");
  const [FileNameErrorfordimanesion, setFileNameErrorForDimension] = useState();
  const [FileNameErrorForSize, setFileNameErrorForSize] = useState();
  const [dataSets, setDatasets] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allUrls, setAllUrls] = useState("");

  const handleCopyClickall = () => {
    const concatenatedUrls = dataSets.map((item) => item?.url).join(",");
    setAllUrls(concatenatedUrls);
  };
  const { data: CompanyData } = useGetCompanyDetails();
  const { data: CompanyTypeData } = useGetCompanyTypeData(
    CompanyData?.data?.companyType
  );

  const handleCopyClick = () => {
    return toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const inputRef = useRef();

  useEffect(() => {
    if (files) {
      let NewData = [];
      for (let i = 0; i < files.length; i++) {
        NewData.push(files[i]);
      }
      setStoreImages(NewData);
    }
  }, [files]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const myForm = new FormData();
  myForm.append("[files]", files);

  const handleChangeFiles = (event) => {
    const newFiles = Array.from(event.target.files);
    const duplicateFiles = newFiles.filter((file) =>
      files.some((existingFile) => existingFile.name === file.name)
    );

    if (duplicateFiles.length > 0) {
      return toast.error("Duplicate Files are not allowed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const uploadData = async (event) => {
    setLoading(true);

    event.preventDefault();
    const formData = new FormData();

    if (files?.length < 3) {
      setFormError(true);
      return toast.error("Please upload at least 3 images", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (files?.length > 6) {
      setFormError(true);
      return toast.error("More than 6 images are not accepted", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    let FilarrayForDimension = [];
    let FilearrayforSize = [];

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];

      try {
        const dataUrl = await readFileAsDataURL(file);
        const img = await loadImage(dataUrl);
      } catch (error) {
        ReadyToUpload = false;
      }
      formData.append("[files]", file);
    }

    setFileNameErrorForDimension(FilarrayForDimension);
    setFileNameErrorForSize(FilearrayforSize);

    if (FilarrayForDimension.length <= 0 && FilearrayforSize.length <= 0) {
      axios({
        method: "post",
        url: "/product/BulkUploadImages",
        data: formData,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
        },
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      })
        .then((res) => {
          setDatasets(res?.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "auto",
          justifyContent: "space-between",
          // bgcolor:"red"
        }}
      >
        <Box sx={FirstBoxStyle}>
          <Box sx={FirstBoxChildStyle}>
            <Typography
              // onClick={uploadData}
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: {
                  xs: "18px",
                  sm: "16px",
                  md: "16px",
                  lg: "14px",
                  xl: "14px",
                },
                color: "#6B7A99",
              }}
            >
              Upload Your Images
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: "20px",
              width: "90%",
            }}
          >
            {" "}
            <Typography sx={{ ...TextStyle, fontSize: "12px" }}>
              Upload Image & Video <span style={{ color: "red" }}> *</span>
            </Typography>
          </Box>
          <Box
            onClick={() => inputRef.current.click()}
            sx={{ ...ImageSelectBoxStyle, cursor: "pointer" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Box
              sx={{
                display: "grid",
                width: "60%",
                mx: " auto",
              }}
            >
              <Box sx={DisplayGridStyle}>
                <Box component="img" src={UploadtoCloud} sx={ImageBox} />
                <Typography
                  sx={{
                    ...TextStyle,
                    display: "flex",
                    justifyContent: "center",
                    color: "#445FD2",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#445FD2",
                    },
                    textalign: "center",
                  }}
                >
                  Drag & Drop | Upload OR
                  <span style={{ color: "#445FD2", fontWeight: 500 }}>
                    &nbsp; Browse &nbsp;
                  </span>
                  to choose a file
                </Typography>
                <Typography sx={MulishFontTextStyle}>
                  Size Limit & Format ( JPEG , PNG - 1 MB , PDF - 5 MB , GIF ,
                  MP4 - 5 MB )
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", mt: "1%" }}>
              {ProductData?.ProductCategoryName === "Textile" ||
              ProductData?.ProductCategoryName === "Office Supply" ||
              ProductData?.ProductCategoryName === "Lifestyle" ||
              ProductData?.ProductCategoryName === "Other" ? (
                <Typography sx={{ ...TextStyle, color: "#445FD2" }}>
                  Aspect Ratio 3 : 4 (1080 X 1920 )
                </Typography>
              ) : (
                <Typography sx={{ ...TextStyle, color: "#445FD2" }}>
                  Aspect Ratio 32 : 9 (3840 X 1080 ) OR 16 : 9 ( 1920 X 1080)
                </Typography>
              )}

              <Typography
                sx={{
                  ...TextStyle,
                  color: "#445FD2",
                }}
              >
                Mandatory Photos : Front View, Back View, Close Fabric View,
              </Typography>
            </Box>
            <input
              type="file"
              multiple
              onChange={handleChangeFiles}
              hidden
              accept=".png,.pdf,.mp4,.jpeg,.gif"
              ref={inputRef}
            />
          </Box>
          {files ? (
            <Box
              sx={{
                width: "90%",
                my: "2%",
              }}
            >
              {/* <Typography sx={TextStyle}>Uploaded</Typography> */}

              {Array?.from(files)?.map((file, idx) => {
                const hasDimensionError = FileNameErrorfordimanesion?.includes(
                  file.name
                );
                const hasSizeError = FileNameErrorForSize?.includes(file.name);

                return (
                  <Box key={idx}>
                    <Box sx={ImageMapBox}>
                      <Typography key={idx} sx={TextStyle}>
                        {file?.name}
                      </Typography>
                      {file.preview && (
                        <Box
                          component="img"
                          src={file.preview}
                          alt={file.name}
                          sx={{
                            height: "100px",
                            width: "100px",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(file.preview, "_blank")}
                        />
                      )}
                      <Box
                        component="img"
                        src={DeleteIcon}
                        sx={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newFiles = Array.from(files);
                          newFiles.splice(idx, 1);
                          setFiles(newFiles);
                          // Remove the image from error arrays if it exists
                          setFileNameErrorForDimension((prevErrors) =>
                            prevErrors?.filter((error) => error !== file.name)
                          );
                          setFileNameErrorForSize((prevErrors) =>
                            prevErrors?.filter((error) => error !== file.name)
                          );
                        }}
                      />
                    </Box>
                    {
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "7px",
                        }}
                      >
                        {hasDimensionError && (
                          <Typography sx={{ color: "black" }}>
                            Dimension Issue:{" "}
                            <span style={{ color: "red" }}>{file.name}</span>
                          </Typography>
                        )}

                        {hasSizeError && (
                          <Typography sx={{ color: "black" }}>
                            Size Issue:{" "}
                            <span style={{ color: "red" }}>{file.name}</span>
                          </Typography>
                        )}
                      </Box>
                    }
                  </Box>
                );
              })}
            </Box>
          ) : (
            " "
          )}

          {loader ? (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                backgroundColor: "transparent",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#445FD2",
                },
              }}
            />
          ) : null}

          <Box
            sx={{
              width: "100%",
              mx: "auto",
              height: "100%",
              bgcolor: "transparent",
              marginBottom: "21px",
            }}
          >
            <BottomNavigation
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "transparent",
                p: "10px",
              }}
              showLabels
            >
              <Box sx={{ display: "flex", gap: "10px", p: 1, width: "50%" }}>
                <Button
                  sx={CancelButtonStyle}
                  variant="contained"
                  onClick={() => {
                    let confirm = window.confirm(
                      "Are you sure you want to cancel the product?"
                    );
                    if (confirm) {
                      navigate("/home/sellerhub");
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={GoLiveButton}
                  variant="contained"
                  onClick={uploadData}
                  // onClick={HandelUploadImages}
                >
                  {loading ? "Loading..." : "Upload Image"}
                </Button>
              </Box>
            </BottomNavigation>
          </Box>
        </Box>

        <Box>
          {Array.isArray(dataSets) &&
            dataSets.map((item, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#EEF1F6",
                  gap: "20px",
                  mt: 2,
                  // p:0.5
                }}
              >
                <img
                  src={item?.url}
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      mt: 5,
                      fontSize: "15px",
                    }}
                  >
                    {item?.url}
                  </Typography>
                </Box>

                <CopyToClipboard text={item?.url} onCopy={handleCopyClick}>
                  <Button>Copy</Button>
                </CopyToClipboard>
                <br />
              </Box>
            ))}
          <CopyToClipboard
            text={allUrls}
            onCopy={() => {
              handleCopyClick(), handleCopyClickall();
            }}
          >
            <Button>Copy All URLs</Button>
          </CopyToClipboard>
        </Box>
      </Box>
    </>
  );
}

const FirstBoxStyle = {
  width: "716px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
  px: "20px",
  backgroundColor: "#EEF1F6",
};

const FirstBoxChildStyle = {
  width: "100%",
  mx: "auto",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  py: "10px",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const ImageBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "5%",
  textalign: "center",
};

const ImageMapBox = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "10px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};

const SizeChartBox = {
  border: "2px dashed #445FD2",
  background: "#fff",
  width: "80%",
  maxWidth: "670px",
  p: "4%",
  mx: "auto",
  position: "relative",
};

const DisplayGridStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  tetxAlign: "center",
};

const MulishFontTextStyle = {
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "8px",
  color: "#676767",
  textAlign: "center",
};
const ImageSelectBoxStyle = {
  border: "2px dashed #445FD2",
  background: "#fff",
  width: "80%",
  maxWidth: "670px",
  p: "4%",
  mx: "auto",
  position: "relative",
};
const Text = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 12,
  ml: "1%",
  mb: "1%",
  pt: "2%",
  // py: 1,
  color: "#6B7A99",
};

const CancelButtonStyle = {
  width: "100%",
  height: "32px",
  borderRadius: "10px",
  background: "#fff",
  color: "#636161",
  fontSize: "14px",
  textTransform: "none",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    background: "#FAFBFD",
    color: "#000",
  },
};

const GoLiveButton = {
  width: "100%",
  height: "32px",
  borderRadius: "10px",
  background: "#445FD2",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    background: "#445FD2",
  },
};

const ResetToDefaultTextStyle = {
  marginRight: "auto",
  p: "2%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  color: "#6B7A99",
  fontSize: 14,
  display: "flex",
  gap: "10px",
};
const SizeChartBoxStyle = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "9px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};
const MenuItemTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  color: "#6B7A99",
};

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const MenuItems = {
  fontSize: "12px",
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};
const GW = {
  width: "20%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#FFFFFF",
  height: "100%",
  color: "#6B7A99",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};

const ToolTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "13px",
  color: "#fff",
  textAlign: "center",
  cursor: "pointer",
};
