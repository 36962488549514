import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LeftArrow from "../../assets/Images/payment/LeftArrow.png";

import { ToastContainer } from "react-toastify";
import { z } from "zod";
import { useCompanyDetails, useCompanyStepDetails } from "../../Hooks/Auth";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import TostMessagesTop from "../../Component/Toast";
import { socket } from "../../pages/Message/Message";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { useDispatch } from "react-redux";
import useGetJustPayPaymentMethds from "../../Hooks/JustPay/useGetJustPayPaymentMethds";
import axios from "axios";
import { useCancleMembershipPaymentMutation } from "../../Hooks/Plan/membershipData.js";

const AddPayment = () => {
  let navigate = useNavigate();
  const { status, order_id } = useParams();
  const [pay, setPay] = useState();
  const [CreditCardPayment, setCreditCardPayment] = useState(true);
  const [cardNumber, setCardNumber] = useState();
  const [expireYear, setExpireYear] = useState();
  const [expireMonth, setExpireMonth] = useState();
  const [countryCode, setCountryCode] = useState();
  const [cvv, setCvv] = useState();
  const [payCode, setPayCode] = useState();
  const [holderName, setHolderName] = useState();
  const [open, setOpen] = useState(false);
  const { mutate: PaymentByCard } = useCompanyStepDetails();
  const { mutate: PaymentByNetBanking, isLoading: PaymentLoading } =
    useCompanyStepDetails();
  const { data: CompanyData } = useCompanyDetails();
  const { data: loggedInUserData } = useGetLoggedInUser();
  const {
    data: JustPayData,
    isLoading: JustPayLoading,
    error: JustPayError,
  } = useGetJustPayPaymentMethds();

  const { mutate: cancleMembershipPayment } =
    useCancleMembershipPaymentMutation();

  const [storePaymentData, setStorePaymentData] = useState("");

  // let CompanyNo = loggedInUserData?.data?.phone;
  // const dispatch = useDispatch();

  // const [orderId, setOrderId] = useState();
  const [payClicked, setPayClicked] = useState(false);
  // const { data } = useGetCompanyPaymentStatusByOrderId(orderId, payClicked);

  // function htmlDecode(input) {
  //   var doc = new DOMParser().parseFromString(input, "text/html");
  //   setPayClicked(true);
  //   return doc.documentElement.textContent;
  // }
  useEffect(() => {
    socket.on("PluralPaymentSuccesfull", (data) => {});
    socket.on("PluralPaymentFailed", (data) => {});
  });

  const makepayment = async () => {
    await axios
      .post(`just-pay/create-order`)
      .then((res) => {
        window.open("/pricing");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);
    const status = urlParams.get("status");
    getCompanyStatusForPayment();
  }, []);

  const [searchParams, _] = useSearchParams();

  async function getCompanyStatusForPayment() {
    await axios
      .post("just-pay/get-order-status", { order_id: order_id })
      .then((res) => {
        if (res?.data?.status === "CHARGED") {
          axios
            .post("just-pay/create-membership", { order_id: order_id })
            .then((res) => {});
          setStorePaymentData("success");
        } else if (res?.data?.status === "CREATED") {
          setStorePaymentData("ongoing");
        } else if (res?.data?.status === "NEW") {
          setStorePaymentData("pending");
        } else {
          setStorePaymentData("failed");
        }
      })
      .catch((err) => {
        setStorePaymentData("failed");
      });
  }

  const [countdown, setCountdown] = useState(5);
  useEffect(() => {
    const timer =
      countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);

    if (countdown === 0 && storePaymentData === "success") {
      navigate("/home/conditions");
    }
    return () => clearInterval(timer);
  }, [countdown]);

  if (payClicked) {
    return (
      <Grid
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Paper elevation={0} sx={{ bgcolor: "transparent" }}>
      <ToastContainer style={{ fontSize: "16px" }} />
      <Paper
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          py: "16px",
          marginBottom: "2rem",
          minHeight: "50px",
          width: "100%",
          mx: "auto",
        }}
        gap={0.5}
        elevation={0}
      >
        <Typography sx={maintext}>Payment</Typography>
      </Paper>
      <Paper elevation={0} sx={{ width: "100%", height: "100%" }}>
        <Box
          component="img"
          src={LeftArrow}
          alt="LeftArrow"
          sx={{
            // height: "auto",
            // width: "auto",
            width: "22px",
            height: "9px",
            mx: "auto",
            p: 2,
          }}
          onClick={() => {
            navigate(-1);
          }}
        ></Box>
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "700px",
            mx: "auto",
            minHeight: "70vh",
          }}
        >
          {!storePaymentData ||
          storePaymentData === undefined ||
          storePaymentData === null ? (
            ""
          ) : storePaymentData === "success" ? (
            <Box sx={{ mt: "100px" }}>
              <Typography sx={mainText}> Payment Successfull</Typography>
              {/* countdown here for 5 sec */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  mx: "auto",
                  mt: 2,
                }}
                gap={2}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  wait for{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#445FD2",
                    }}
                  >
                    {countdown}
                  </span>{" "}
                  sec we are navigating you to profile
                </Typography>
              </Box>
            </Box>
          ) : storePaymentData === "ongoing" ? (
            <Button
              sx={{
                width: "auto",
                height: "auto",
                fontSize: "16px",
                fontWeight: "500",
                color: "#fff",
                backgroundColor: "#445FD2",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              Refresh to check payment Status
            </Button>
          ) : storePaymentData === "pending" ? (
            <Box
              sx={{
                mt: "80px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography sx={{ ...mainText, color: "yellow" }}>
                {" "}
                Payment Pending{" "}
              </Typography>
              <Button
                variant="standard"
                size="large"
                sx={{
                  color: "blue",
                  width: "50%",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: {
                    xl: "1.2rem",
                    lg: "1.2rem",
                    md: "1rem",
                    sm: "0.8rem",
                    xs: "0.8rem",
                  },
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  cancleMembershipPayment(
                    {},
                    {
                      onSuccess: () => {
                        navigate(-1);
                      },
                    }
                  );
                }}
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                mt: "80px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography sx={{ ...mainText, color: "red" }}>
                {" "}
                Payment failed{" "}
              </Typography>
              <Button
                onClick={makepayment}
                variant="contained"
                sx={{
                  color: "white",
                  width: "200px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                Pay Now
              </Button>
              <Button
                variant="standard"
                size="large"
                sx={{
                  color: "blue",
                  width: "50%",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: {
                    xl: "1.2rem",
                    lg: "1.2rem",
                    md: "1rem",
                    sm: "0.8rem",
                    xs: "0.8rem",
                  },
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  cancleMembershipPayment(
                    {},
                    {
                      onSuccess: () => {
                        navigate(-1);
                      },
                    }
                  );
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Grid>
      </Paper>
      <TostMessagesTop PageLocation="Bank Details" />
    </Paper>
  );
};

export default AddPayment;

const mainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "3.2rem",
    lg: "3.1rem",
    md: "3rem",
    sm: "2.8rem",
    xs: "2.6rem",
  },
  textAlign: "center",
  color: "#6B7A99",
};

const maintext = {
  fontFamily: "Poppins",
  fontStyle: "SemiBold",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2rem",
    sm: "1.8rem",
    xs: "1.6rem",
  },
  mt: 1,
};
