import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Input,
  Paper,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import useStyles from "./Styles";
import EmptyCart from "./EmptyCart";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { BiCheckbox } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import sendEvents from "../../utils/sendEvents.js";
import CommaSeprator from "../../components/CommaSeprator";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import { get_Cart_Items } from "../../redux/action/CartActions";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";
import * as Styles from "../../components/common/Styled/Styles.js";
import addItemCartIcon from "../../assets/CartPage/addItemIcon.svg";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import TrashCanIcon from "../../assets/WishlistPage/TrashCanicon.svg";
import { useGetCartData } from "../../Hooks/ProductActions/useGetCartData";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import { useRemoveCartProduct } from "../../Hooks/ProductActions/useRemoveCartProduct";
import { notifications } from "../../redux/action/Notification/notification.js";
import { useEffectOnce } from "react-use";

const ProductCart = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Order, setOrder] = useState([]);
  const [open, setOpen] = useState(false);
  const [Itemqty, setItemQty] = useState(0);
  const [balance, setBalance] = useState("");
  const [EditItem, setEditItem] = useState(null);
  const [AllProducts, setAllProducts] = useState([]);
  const [CartRemoveData, setCartRemoveData] = useState({});
  const [ProfilePercentage, setProfilePercentage] = useState(0);
  const [OutOfStockProducts, setOutOfStockProducts] = useState([]);
  const { data: cartItems, refetch: cartRefetch } = useGetCartData();

  // open cart item details
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // store cart data
  function storeData(params) {
    const orderData = Order.find((el) => el?._id === params?._id);
    if (orderData) {
    } else {
      setOrder([...Order, params]);
    }
  }

  useEffectOnce(() => {
    setOrder(cartItems);
  }, []);

  // logged user
  const { data: IAMUserData } = useGetAuthUser();

  // here we GET wallet balance
  const reqBalance = async () => {
    await axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.body);
      });

    await axios
      .get(
        "auth/profilecompletion",
        {
          withCredentials: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let findPercentage =
          typeof res?.data?.ProfileMessage === "string"
            ? res?.data?.ProfileMessage.split(" ")
            : null;

        let finalPercentage =
          findPercentage && findPercentage.length >= 4
            ? findPercentage[3]?.toString()?.split(".")
            : null;
        setProfilePercentage(finalPercentage[0]);
      });
  };

  useEffect(() => {
    reqBalance();
  }, []);

  // inital all value set up here
  let TotalQuantity = 0;
  let Totalgst = 0;
  let TotalGSTAmount = 0;
  let TotalAdditionalCostInRupee = 0;
  let TotalAdditionalCostInBXI = 0;
  let TotalAdditionalCostGST = 0;
  let TotalAdditionalCostGSTAmount = 0;
  let totalPrice = 0;
  let dataId = [];
  let ToTalGstPercentage = 0;
  let TotalfinalPrice = 0;
  let GrandTotal = 0;

  let AdCostTotalGST = 0;

  let DataToBeSentToBackend = [];

  Order?.map((el) => {
    el?.AdditionCostArrayData?.map((data) => {
      AdCostTotalGST += data?.GstPrice;
    });

    DataToBeSentToBackend.push({
      ProductId: el.ProductId?._id,
      ProductName: el.ProductId.ProductName,
      ProductPrice: el.PricePerUnit * el.ProductQuantity,
      ProductQty: el.ProductQuantity,
      ProductGst: el.ProductGst,
      SellerCompanyId: el.ProductId.SellerCompanyId,
      ProductsVariantionId: el.ProductsVariantionId,

      TotalAdditionalCostInRupee: el.TotalAdditionalCostInRupee,
      TotalAdditionalCostInBXI: el.TotalAdditionalCostInBXI,
      TotalAdditionalCostGST: el.TotalAdditionalCostGST,
      TotalAdditionalCostGSTAmount: el.TotalAdditionalCostGSTAmount,
      TotalGSTAmount: el.TotalGSTAmount,
    });

    GrandTotal += el?.Total;
    TotalQuantity = TotalQuantity + el.ProductQuantity;
    totalPrice += +el.PricePerUnit * el.ProductQuantity;
    Totalgst += +el.ProductGst;
    TotalGSTAmount = TotalGSTAmount + el.TotalGSTAmount;
    TotalAdditionalCostInRupee =
      TotalAdditionalCostInRupee + el.TotalAdditionalCostInRupee;
    TotalAdditionalCostInBXI =
      TotalAdditionalCostInBXI + el.TotalAdditionalCostInBXI;
    TotalAdditionalCostGST = TotalAdditionalCostGST + el.TotalAdditionalCostGST;
    TotalAdditionalCostGSTAmount += el.TotalAdditionalCostGSTAmount;
    ToTalGstPercentage = ToTalGstPercentage + el.GSTPercentage;

    dataId.push(el._id);
  });

  let TotalAdCostInr = 0;
  let TotalAdCostBXI = 0;
  let TotalCoins = 0;

  Order?.map((el) => {
    el?.AdditionCostArrayData?.map((el) => {
      if (el?.currencyType === "₹") {
        TotalAdCostInr += el?.TotalWithGst;
      } else {
        TotalCoins += el?.PriceWithoutGST;
        TotalAdCostBXI += el?.TotalWithGst;
      }
    });
  });

  TotalfinalPrice =
    GrandTotal +
    TotalGSTAmount +
    TotalAdCostBXI +
    TotalAdCostInr +
    TotalAdditionalCostInRupee +
    TotalAdditionalCostInBXI;

  // remover cart api fetch here
  const {
    mutate: removeCartProduct,
    data: removeCartProductData,
    error: removeCartProductError,
    isLoading: removeCartProductIsLoading,
    refetch: removeCartProductRefetch,
  } = useRemoveCartProduct();

  // click to remove
  const handleRemoveCartProduct = async (id) => {
    removeCartProduct(CartRemoveData?.id);
    setOpen(false);
  };

  useEffect(() => {
    cartRefetch();
  }, [removeCartProductData]);

  // multipl product remove here
  async function handleRemoveMultipleCartProduct() {
    dataId.map((el) => {
      removeCartProduct(el);
      cartRefetch();
    });
    cartRefetch();
    setOrder([]);
  }
  async function handleRemoveCartplaceorder() {
    dataId.map((el) => {
      removeCartProduct(el);
      cartRefetch();
    });
    cartRefetch();
    setOrder([]);
  }

  const CreatePurchaseOrder = async (OrderData) => {
    try {
      await axios
        .post("purchase/create_purchase_order", { OrderData })
        .then((response) => {
          if (
            response?.data?._message &&
            response?.data?._message !== "Order Created"
          ) {
            GlobalToast(response?.data?._message);
          } else {
            handleRemoveCartplaceorder();
            GlobalToast("Purchase order created. Kindly review and accept.");
            setTimeout(() => {
              navigate("/home/purchaseorderlist");
            }, 3000);
          }
        })
        .catch((err) => {});
    } catch (err) {
      GlobalToast(err?.message);
    }
  };

  // create order before check all condition
  async function handleCreateOrder() {
    if (ProfilePercentage) {
      if (ProfilePercentage && ProfilePercentage >= 100) {
        CreatePurchaseOrder(DataToBeSentToBackend);
      } else {
        GlobalToast(
          "100% Profile Completion is Mandatory to Start Trading. It is important for business documents.",
          "error"
        );
        navigate("/home/profile");
      }
    } else {
      alert("Please wait while we check your profile before creating Order ");
    }
    cartRefetch();
  }

  function removeData(params) {
    const orderData = Order.find((el) => el._id === params._id);
    if (orderData) {
      const newOrder = Order.filter((el) => el._id !== params._id);
      cartRefetch();
      setOrder(newOrder);
    } else {
    }
  }

  useEffect(() => {
    dispatch(get_Cart_Items());
  }, []);

  const getAllProducts = async () => {
    try {
      const response = await axios.get(`/product/productinstock`, {
        withCredentials: true,
      });
      setAllProducts(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (AllProducts && cartItems) {
      const outOfStockItems = [];

      for (let product of AllProducts) {
        for (let card in cartItems) {
          const cardItem = cartItems[card];

          if (
            product?.ProductsVariantions?.[0]?.MaxOrderQuantity === 0 &&
            product?._id === cardItem?.ProductId?._id
          ) {
            outOfStockItems.push(cardItem);
          }
        }
      }

      setOutOfStockProducts(outOfStockItems);
    }
  }, [AllProducts, cartItems]);

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        borderRadius: "0px",
        boxShadow: "none",
        width: "100%",
      }}
      elevation={0}
    >
      {cartItems?.length > 0 ? (
        <React.Fragment>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            {cartItems?.map((el, idx) => {
              let ProductVariation = el?.ProductId?.ProductsVariantions.find(
                (item) => {
                  return el.ProductsVariantionId === item._id;
                }
              );
              let maxOrderQuantity = ProductVariation.MaxOrderQuantity;
              let minOrderQuantity = ProductVariation.MinOrderQuantity;

              const isOutOfStock = OutOfStockProducts.some(
                (item) => item.ProductId?._id === el.ProductId?._id
              );
              return (
                <Box key={idx}>
                  <Box className={classes.rootbox}>
                    <Box className={classes.rootboxChildOne}>
                      <Box
                        className={classes.cartProductStrip}
                        sx={{
                          backgroundImage: `url(${el?.ProductId?.ProductImages[0]?.url})`,
                        }}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          alignContent: "start",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <Typography sx={Styles.ProductNameTextStyle}>
                          {el?.ProductId?.ProductName}
                        </Typography>
                        <Typography
                          sx={{
                            ...Styles.ProductNameTextStyle,
                            fontSize: "11px",
                          }}
                        >
                          {el?.ProductId?.ProductDescription}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={Styles.ProductPriceTextStyle}>
                            <img
                              src={StacsOfCoinIcon}
                              alt="rupieicon"
                              style={{
                                width: "17px",
                                height: "auto",
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              ...Styles.ProductPriceTextStyle,
                              marginTop: "-03px",
                            }}
                          >
                            &nbsp;
                            <CommaSeprator Price={el?.PricePerUnit} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.rootboxChildOne}>
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        Min : {minOrderQuantity}
                      </Typography>
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        Max : {maxOrderQuantity}
                      </Typography>
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        QTY : {el?.ProductQuantity}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setEditItem(el._id);
                          setItemQty(el?.ProductQuantity);
                        }}
                      >
                        Edit
                      </Button>
                      {EditItem === el._id ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={btnDesign}
                            onClick={() => {
                              if (maxOrderQuantity <= Itemqty) {
                                return;
                              }
                              setItemQty(Itemqty + 1);
                            }}
                          >
                            +
                          </Box>
                          <Input
                            value={Number(Itemqty)}
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            placeholder="Password"
                            sx={{
                              width: "38px",
                              height: "38px",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              fontSize: "10px",
                              color: "#445FD2",
                              border: "1px solid rgba(209, 209, 209, 1)",
                            }}
                            inputProps={{
                              style: {
                                color: "#445FD2",
                                fontSize: "14px",
                                padding: "5px",
                                paddingLeft: "5px",
                              },
                            }}
                          />
                          <Box
                            sx={btnDesign}
                            variant="outlined"
                            onClick={() => {
                              if (minOrderQuantity >= Itemqty) {
                                return;
                              }
                              setItemQty(Itemqty - 1);
                            }}
                          >
                            -
                          </Box>
                          <Button
                            variant="contained"
                            onClick={async () => {
                              await axios
                                .put("product/update_cart_qty", {
                                  cartid: el._id,
                                  NewQty: Itemqty,
                                  productId: el?.ProductId,
                                  productVariationId: el?.ProductsVariantionId,
                                })
                                .then((res) => {
                                  cartRefetch();
                                  setOrder([]);
                                  toast.success(res.data);
                                  setEditItem(null);
                                })
                                .catch((err) => {
                                  toast.error(err.response.data);
                                });
                            }}
                          >
                            Confirm
                          </Button>
                        </Box>
                      ) : null}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        Click to Select and Proceed
                      </Typography>
                      {isOutOfStock ? (
                        <Typography sx={{ color: "red" }}>
                          Out of Stock
                        </Typography>
                      ) : dataId.includes(el._id) ? (
                        <Box
                          onClick={() => {
                            removeData(el);
                            sendEvents("Click on add item to cart");
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <img src={addItemCartIcon} size={35} />
                        </Box>
                      ) : (
                        <Box
                          onClick={() => {
                            storeData(el);
                            sendEvents("Click on checkbox");
                          }}
                          className="cartcheckbox"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <BiCheckbox size={35} />
                        </Box>
                      )}
                      <Box
                        onClick={() => {
                          handleClickOpen();
                          setCartRemoveData({
                            id: el._id,
                            ProductName: el?.ProductId?.ProductName,
                          });
                          removeData(el);
                          sendEvents("Click on close button");
                        }}
                      >
                        <IoClose
                          size={25}
                          style={{
                            cursor: "pointer",
                          }}
                          sx={{
                            color: "rgba(104, 107, 111, 1)",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={Styles.GridStyle}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
                bgcolor: "transparent",
                justifyContent: "flex-start",
              }}
            >
              {" "}
              <Box
                component={"img"}
                src={addItemCartIcon}
                alt="icon"
                style={{ cursor: "pointer" }}
              ></Box>
              <Typography
                sx={{ ...Styles.cartSelectionTextStyle, display: "flex " }}
              >
                {Order?.length}/{cartItems?.length}
                &nbsp;Items&nbsp;Selected&nbsp; (&nbsp;
                {TotalfinalPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={StacsOfCoinIcon}
                      style={{
                        width: "auto",
                        height: "23px",
                        marginRight: "5px",
                      }}
                      alt="rupieicon"
                    />
                    <Typography sx={Styles.cartSelectionTextStyle}>
                      <CommaSeprator Price={GrandTotal + TotalCoins} /> + ₹
                      &nbsp;
                      <CommaSeprator
                        Price={TotalGSTAmount + TotalAdCostInr + AdCostTotalGST}
                      />
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={StacsOfCoinIcon}
                      style={{
                        width: "23px",
                        height: "auto",
                      }}
                      alt="rupieicon"
                    />
                    <Typography sx={Styles.cartSelectionTextStyle}>
                      0
                    </Typography>
                  </Box>
                )}
                &nbsp;)
                {"   "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "2rem" }}>
              {" "}
              <Box
                component={"img"}
                src={TrashCanIcon}
                alt="trashIcon"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  let confirm = window.confirm(
                    "Are you sure you want to delete selected items from cart?"
                  );
                  if (confirm === true) {
                    handleRemoveMultipleCartProduct();
                  } else {
                    return;
                  }
                  sendEvents("Click on trash");
                }}
              ></Box>
            </Box>
          </Grid>
          {Order?.length === 0 ? null : (
            <React.Fragment>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  background: "#fff",
                  padding: "4rem",
                  borderRadius: "10px",
                  marginTop: "2rem",
                }}
              >
                <Typography sx={{ ...Styles.cartSelectionTextStyle }}>
                  Purchase Details&nbsp;({Order?.length} Items){"   "}
                </Typography>

                <TableContainer sx={{ marginTop: "3rem", width: "100%" }}>
                  <TableHead>
                    <TableRow sx={{ height: "42px", bgcolor: "#2261A2" }}>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD", width: "50px" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          {" "}
                          S. No.
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={2}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Product Name
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Price / Unit{" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Quantity
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          GST(%)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Total Price (Excluding GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          GST Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Additional Cost INR (INC GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Additional Cost Barter Coins (INC GST)
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          bgcolor: "#2261A2",
                          width: "170px",
                          borderLeft: "1px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Grand Total
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Order?.map((row, idx) => {
                      let AdCostInInr = 0;
                      let AdCostInBXI = 0;
                      row?.AdditionCostArrayData?.map((item) => {
                        if (item?.currencyType === "₹") {
                          AdCostInInr = AdCostInInr + item?.TotalWithGst;
                        } else {
                          AdCostInBXI = AdCostInBXI + item?.TotalWithGst;
                        }
                      });

                      let TotalFinal =
                        row?.Total +
                        row?.TotalGSTAmount +
                        AdCostInBXI +
                        AdCostInInr +
                        row?.TotalAdditionalCostInBXI +
                        row?.TotalAdditionalCostInRupee;

                      return (
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderLeft: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {idx + 1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductId?.ProductName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.PricePerUnit} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.ProductQuantity} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.ProductGst} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.Total} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.TotalGSTAmount} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {" "}
                              <CommaSeprator Price={AdCostInInr} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={AdCostInBXI} />
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={TotalFinal} />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow
                      sx={{
                        height: "42px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={3}
                        rowSpan={3}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderLeft: "1px solid #CDCDCD",
                          background: "#2261a2",
                        }}
                      >
                        <Typography
                          sx={{ ...Styles.TableBottomtext, color: "#fff" }}
                        >
                          Total
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalQuantity} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>-</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={GrandTotal} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalGSTAmount} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalAdCostInr} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalAdCostBXI} />
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalfinalPrice} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
                <Button
                  sx={Styles.CreateOrderButtonStyle}
                  onClick={() => {
                    handleCreateOrder();
                    sendEvents("Click on generate purchase order");
                  }}
                >
                  Generate Purchase Order & View PO List
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <EmptyCart />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {CartRemoveData?.ProductName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item from cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              sendEvents("Click on close");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemoveCartProduct(CartRemoveData?.id);
              sendEvents("Click on remove");
            }}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ProductCart;

const btnDesign = {
  border: "0.5px solid #445FD2",
  borderRadius: "50%",
  p: 1,
  width: "15px",
  height: "15px",
  fontSize: "15px",
  color: "#445FD2",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  textAlign: "center",
  "&:hover": {
    background: "#445FD2",
    color: "#fff",
  },
};
