import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CardOne from "../CardOne";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { voucherStyle } from "./EditVoucherStyle";

const Template = ({
  tempOne = false,
  tempTwo = false,
  tempThree = false,
  tempFour = false,
  tempFive = false,
  cardBgColor,
  cardImage,
  category,
  templateId,
  productData,
  textInverted,
  iconInverted,
  myRefFront,
  myRefBack,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [openLeftEdit, setOpenLeftEdit] = useState(false);
  const [helperText, setHelperText] = useState("Choose wisely");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(" ");
    setError(false);
  };

  const classes = voucherStyle();

  return (
    <>
      <Box
        className={classes.cardContainer}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "20px",
          alignItems: "normal",
        }}
      >
        {/* <Grid item xs={12} lg={12} sm={12} md={12}> */}
        <CardOne
          leftCard
          tempOne={templateId == "Template1" && true}
          tempTwo={templateId == "Template2" && true}
          tempThree={templateId == "Template3" && true}
          cardBgColor={cardBgColor}
          showLeftEdit={openLeftEdit}
          closePopup={() => {
            setOpenLeftEdit(false);
          }}
          cardImage={cardImage}
          category={category}
          // myRefBack={myRefBack}
          myRefFront={myRefFront}
          templateId={templateId}
          productData={productData}
          textInverted={textInverted}
          iconInverted={iconInverted}
        />
        {/* </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12} mt={2}> */}
        <CardOne
          rightCard
          tempOne={templateId == "Template1" && true}
          tempTwo={templateId == "Template2" && true}
          tempThree={templateId == "Template3" && true}
          cardBgColor={cardBgColor}
          // cardImage={cardImage}
          myRefBack={myRefBack}
          // myRefFront={myRefFront}
          showLeftEdit={openLeftEdit}
          closePopup={() => {
            setOpenLeftEdit(false);
          }}
          templateId={templateId}
          productData={productData}
          textInverted={textInverted}
          iconInverted={iconInverted}
        />
        {/* </Grid> */}
      </Box>
    </>
  );
};

export default Template;
