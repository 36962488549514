import React, { useState } from "react";
import { Button, Typography, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import newbarterLogo from "../../assets/SidebarIcon/BxiLogo.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ConfirmationBox = ({ message, onConfirm, onCancel }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.box}>
        <p style={styles.textStyle}>{message}</p>
        <div style={styles.buttons}>
          <button onClick={onConfirm} style={styles.confirmButton}>
            Confirm
          </button>
          <button onClick={onCancel} style={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const StyledContainer = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100%",
  backgroundColor: "transparent",
  position: "relative",
}));

const BorderBox = styled(Box)(() => ({
  border: `1px solid #415bca`,
  borderRadius: "8px",
  padding: "40px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  textAlign: "center",
  width: "600px",
  height: "200px",
}));

const StyledButton = styled(Button)(({ variant }) => ({
  padding: "12px 35px",
  fontSize: "16px",
  fontWeight: "bold",
  borderRadius: "8px",
  textTransform: "none",
  position: "relative",
  transition: "0.3s",
  margin: "8px",
  ...(variant === "confirm" && {
    backgroundColor: "#415bca",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #415bca",
      color: "#415bca",
    },
  }),
}));

const EnachConfirmation = () => {
  const params = useParams();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleConfirmationBox = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    createRecurringTransaction({
      OrderId: params?.id,
      hashCodeNumber: params?.number,
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const createRecurringTransaction = async ({ OrderId, hashCodeNumber }) => {
    try {
      await axios
        .post(`enach_mandate/create_recurring_transaction`, {
          OrderId,
          hashCodeNumber,
        })
        .then((response) => {
          if (response?.data?.response?.amount) {
            toast.success("Transaction completed successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/home");
            }, 3000);
          }
        });
    } catch (error) {
      toast.error("Failed to complete transaction", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <StyledContainer>
      {showConfirmation && (
        <ConfirmationBox
          message="Are you sure you want to confirm this debit?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <Box>
        <img
          src={newbarterLogo}
          style={{
            width: "70px",
            height: "auto",
            top: "50px",
            left: "03%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        />
      </Box>
      <BorderBox>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: "Poppins" }}>
          Confirm your mandate amount debit from your account
        </Typography>
        <Typography sx={{ mb: 4, fontSize: "12px", fontFamily: "Poppins" }}>
          We need your confirmation to proceed with this action.
        </Typography>
        <div>
          <StyledButton variant="confirm" onClick={handleConfirmationBox}>
            Confirm
          </StyledButton>
        </div>
      </BorderBox>
    </StyledContainer>
  );
};

export default EnachConfirmation;

const styles = {
  overlay: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    height: "100%",
    zIndex: "10",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    backgroundColor: "white",
    padding: "20px",
    width: "450px",
    height: "170px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "500",
    color: "#415bca",
    marginBottom: "20px",
  },
  buttons: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "300px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  confirmButton: {
    backgroundColor: "#415bca",
    color: "white",
    padding: "12px 30px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
    },
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#415bca",
    padding: "12px 30px",
    border: "1px solid #415bca",
    borderRadius: "4px",
    cursor: "pointer",
  },
};
