import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StacsOfCoinIcon from "../../assets/Images/CommonImages/BXIToken.svg";
import CommaSeprator from "../../components/CommaSeprator";
import useGetCompanyFullData from "../../Hooks/CompanyData/useGetCompanyFullData";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import axios from "axios";
import OrderProgressBar from "../../components/ProgressBar/OrderProgressBar";

const MediaOrderSummary = ({ MediaSummaryData, CompanyData }) => {
  const componentRef = useRef();

  const [MemberShipData, setMemberShipData] = useState();

  const itemDetails = [
    {
      item: "Seller Name :",
      value:
        MediaSummaryData?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
    },
    {
      item: "Address :",
      value:
        MediaSummaryData?.PurchaseOrderData?.SellerDetails?.Address
          ?.AddressLine,
    },
    {
      item: "GSTIN :",
      value: MediaSummaryData?.PurchaseOrderData?.SellerDetails?.GSTIN,
    },
    {
      item: "State :",
      value: MediaSummaryData?.PurchaseOrderData?.SellerDetails?.Address?.State,
    },
  ];
  const pror = [
    {
      item: "Purchase Order Number :",
      value: MediaSummaryData?.PurchaseOrderData?.PoNumber,
    },
    {
      item: "Purchase Order Date :",
      value: new Date(MediaSummaryData?.PoDate).toDateString(),
    },
  ];

  let Seller_amount = 0;
  let BXI_amount = 0;
  let Coins = 0;

  if (MediaSummaryData) {
    Seller_amount =
      MediaSummaryData?.INRDetails?.TotalGstInrOfAdCost +
      MediaSummaryData?.INRDetails?.TotalValueInrOfAdCost +
      MediaSummaryData?.INRDetails?.TotalGstAmount;

    BXI_amount =
      MediaSummaryData?.INRDetails?.TotalBxiCommission +
      MediaSummaryData?.INRDetails?.BxiCommisionGST -
      MediaSummaryData?.INRDetails?.TDS;

    Coins = MediaSummaryData?.WalletData?._balance;
  }

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let TotalPriceWithoutGST = 0;
  let GrandTotal = 0;
  let totaladditionalcostGstinrupee = 0;
  let totaladditionalcostGstinbxi = 0;

  let BuyerId = MediaSummaryData?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [MediaSummaryData]);

  let BxiCommision = MediaSummaryData?.PurchaseOrderData?.CouponData
    ?.BXICommission
    ? Number(MediaSummaryData?.PurchaseOrderData?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      taxableValue:
        (Number(MediaSummaryData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(MediaSummaryData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;

  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let totalTaxAmount =
    MediaSummaryData?.INRDetails?.TotalGstAmount +
    MediaSummaryData?.INRDetails?.TotalGstInrOfAdCost +
    ChargesTaxableAmount;

  MediaSummaryData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    TotalAdditionalCostWtihoutGSTInBXI +=
      el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;

    if (
      el &&
      (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
        el?.AdditionCost?.AdditionCostArrayData?.length > 0)
    ) {
      const additionalCostArray =
        el?.AdditionCost?.AdditionCostArray?.length > 0
          ? el?.AdditionCost?.AdditionCostArray
          : el?.AdditionCost?.AdditionCostArrayData;

      // Iterate over the array
      additionalCostArray.forEach((item, index) => {
        if (item.currencyType === "₹") {
          totaladditionalcostGstinrupee +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        } else if (item.currencyType === "BXITokens") {
          totaladditionalcostGstinbxi +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        }
      });
    }

    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;

    GrandTotal += el?.TotalPriceWithoutGST;
  });
  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        boxShadow: "none",
        borderRadius: "20px",
        height: "auto",
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
      // ref={componentRef}
    >
      <OrderProgressBar type={"mediaordersummary"} Data={MediaSummaryData} />
      <Box
        ref={componentRef}
        sx={{ width: "100%", mt: "20px", bgcolor: "#fff" }}
      >
        <Grid
          container
          gap={8}
          sx={{
            py: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xl={5.5}
            lg={5.5}
            md={5.5}
            sm={12}
            xs={12}
            sx={{
              ...gridstyle,
              width: "830px",
              height: "auto",
              position: "relative",
            }}
          >
            <Box sx={mainbox}>
              <Typography sx={headbox}>
                &nbsp;&nbsp;&nbsp;Supplier Details
              </Typography>
              <Typography
                sx={{
                  ...headbox,
                  fontSize: "14px",
                  float: "right",
                  textAlign: "right",
                  mr: "10px",
                }}
              >
                Order Id : {MediaSummaryData?.OrderId}
              </Typography>
            </Box>
            <Box sx={contentbox}>
              {itemDetails?.map((el, idx) => {
                return (
                  <Box sx={mapbox} key={idx}>
                    <Typography sx={elitem}>{el.item}</Typography>
                    <Typography
                      sx={{
                        ...elvalue,
                        width: "85%",
                        textAlign: "left",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.value}
                    </Typography>
                  </Box>
                );
              })}

              <Box sx={{ mt: "20px", width: "90%" }}>
                {pror?.map((el, idx) => {
                  return (
                    <Box sx={{ ...mapbox }} key={idx}>
                      <Typography
                        sx={{
                          ...elitem,
                          width: "300px",
                          textAlign: "left",
                        }}
                      >
                        {el.item}
                      </Typography>
                      <Typography
                        sx={{
                          ...elvalue,
                          width: "85%",
                          textAlign: "left",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {el.value}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Typography
                sx={{
                  ...selername,
                  justifyContent: "flex-end",
                  position: "absolute",
                  mt: 8,
                }}
              >
                Code :{" "}
                {MediaSummaryData?.PurchaseOrderData?.SellerDetails?.StateCode}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xl={5.5}
            lg={5.5}
            md={5.5}
            sm={12}
            xs={12}
            sx={{
              ...gridstyle,
              width: "399px",
              height: "100%",
              minHeight: "380px",
            }}
          >
            <Box sx={headbox2}>
              <Typography sx={detailtext}>Coins + INR Details</Typography>
              <Typography sx={detailtext2}>Details with more info</Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
              }}
            >
              {/* <Box sx={mapbox}>
                <Typography sx={inrvalue}>Product Purchase GST </Typography>
                <Typography sx={inrvalue}>
                  <CommaSeprator
                    Price={MediaSummaryData?.INRDetails?.TotalGstAmount}
                  />
                </Typography>
              </Box>

              <Box sx={mapbox}>
                <Typography sx={inrvalue}>Additional Cost</Typography>
                <Typography sx={inrvalue}>
                  <CommaSeprator
                    Price={
                      MediaSummaryData?.INRDetails?.TotalGstInrOfAdCost +
                      MediaSummaryData?.INRDetails?.TotalValueInrOfAdCost
                    }
                  />
                </Typography>
              </Box>
              <Box sx={mapbox}>
                <Typography sx={inrvalue}>
                  Bxi Commission(
                  {CompanyData?.ChoosedPlan === "advance" ? "8" : "12"}%)
                </Typography>
                <Typography sx={inrvalue}>
                  <CommaSeprator
                    Price={MediaSummaryData?.INRDetails?.TotalBxiCommission}
                  />{" "}
                </Typography>
              </Box>
              <Box sx={mapbox}>
                <Typography sx={inrvalue}>GST on Commision (18%)</Typography>
                <Typography sx={inrvalue}>
                  <CommaSeprator
                    Price={MediaSummaryData?.INRDetails?.BxiCommisionGST}
                  />
                </Typography>
              </Box>

              <Box
                sx={{
                  ...totaltextbox,
                  borderBottom: "1px solid rgba(149, 144, 168, 0.1)",
                }}
              >
                <Box sx={totaltextsec}>
                  <Typography sx={totaltext}>
                    Total without TDS Deduction
                  </Typography>
                  <Typography sx={totaltext}>
                    ₹{" "}
                    <CommaSeprator
                      Price={
                        MediaSummaryData?.INRDetails?.TotalInrToPay +
                        MediaSummaryData?.INRDetails?.TDS
                      }
                    />
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  ...totaltextbox,
                  borderTop: "none",
                  borderBottom: "1px solid rgba(149, 144, 168, 0.1)",
                }}
              >
                <Box sx={totaltextsec}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      textAlign: "center",
                      color: "#6B7A99",
                    }}
                  >
                    Deducted TDS (5%) on BXI Commission
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    - ₹{" "}
                    <CommaSeprator Price={MediaSummaryData?.INRDetails?.TDS} />
                  </Typography>
                </Box>
              </Box>

              <Box sx={totaltextsec}>
                <Typography sx={totaltext}>Total Payment</Typography>
                <Typography sx={totaltext}>
                  ₹{" "}
                  <CommaSeprator
                    Price={MediaSummaryData?.INRDetails?.TotalInrToPay}
                  />
                </Typography>
              </Box>

              <Box sx={mapbox}>
                <Typography sx={{ ...inrvalue, opacity: 0.4 }}>
                  <i>Payment to Seller</i>
                </Typography>
                <Typography sx={{ ...inrvalue, opacity: 0.4 }}>
                  <i>
                    ₹ <CommaSeprator Price={Seller_amount} />
                  </i>
                </Typography>
              </Box>

              <Box sx={mapbox}>
                <Typography sx={{ ...inrvalue, opacity: 0.4 }}>
                  <i>Payment to BXI</i>
                </Typography>
                <Typography sx={{ ...inrvalue, opacity: 0.4 }}>
                  <i>
                    ₹ <CommaSeprator Price={BXI_amount} />
                  </i>
                </Typography>
              </Box> */}
              <Table sx={{ maxWidth: "85%" }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Particulars
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Basic Value
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        GST (INR)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Total (Coins+INR)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Product Cost */}
                  {MediaSummaryData?.INRDetails?.totalGstAmount !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Product Cost (Coins){" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              TotalPriceWithoutGST
                                ? TotalPriceWithoutGST
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={MediaSummaryData?.INRDetails?.TotalGstAmount}
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails?.TotalGstAmount +
                              TotalPriceWithoutGST
                                ? MediaSummaryData?.INRDetails?.TotalGstAmount +
                                  TotalPriceWithoutGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Additional Cost (INR)*/}
                  {MediaSummaryData?.INRDetails?.TotalGstInrOfAdCost +
                    MediaSummaryData?.INRDetails?.TotalValueInrOfAdCost !==
                    0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Additional Cost (INR)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails
                                ?.TotalValueInrOfAdCost
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              totaladditionalcostGstinrupee
                                ? totaladditionalcostGstinrupee
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {MediaSummaryData?.INRDetails
                            ?.TotalValueInrOfAdCost ? (
                            <>
                              {MediaSummaryData?.INRDetails
                                ?.TotalValueInrOfAdCost !== 0 && (
                                <CommaSeprator
                                  Price={
                                    MediaSummaryData?.INRDetails
                                      ?.TotalValueInrOfAdCost +
                                    totaladditionalcostGstinrupee
                                  }
                                />
                              )}
                            </>
                          ) : MediaSummaryData?.POTotals
                              ?.TotalAdditionalCostInRupee ? (
                            <>
                              {MediaSummaryData?.POTotals
                                ?.TotalAdditionalCostInRupee !== 0 && (
                                <CommaSeprator
                                  Price={
                                    MediaSummaryData?.POTotals
                                      ?.TotalAdditionalCostInRupee +
                                    totaladditionalcostGstinrupee
                                  }
                                />
                              )}
                            </>
                          ) : (
                            "0.00"
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Additional Cost (Coins)*/}
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderLeft: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "left" }}
                      >
                        Additional Cost (Coins)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI
                              ? TotalAdditionalCostWtihoutGSTInBXI
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            totaladditionalcostGstinbxi
                              ? totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI +
                            totaladditionalcostGstinbxi
                              ? TotalAdditionalCostWtihoutGSTInBXI +
                                totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* BXI Commission */}
                  {MediaSummaryData?.INRDetails?.TotalBxiCommission !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          BXI Commission
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails?.TotalBxiCommission
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails?.BxiCommisionGST
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails?.TotalBxiCommission
                                ? MediaSummaryData?.INRDetails
                                    ?.TotalBxiCommission +
                                  MediaSummaryData?.INRDetails?.BxiCommisionGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            MediaSummaryData?.INRDetails?.WithoutGSTValue
                              ? MediaSummaryData?.INRDetails?.WithoutGSTValue
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={totalTaxAmount ? totalTaxAmount : 0.0}
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            MediaSummaryData?.INRDetails?.WithoutGSTValue +
                            totalTaxAmount
                              ? MediaSummaryData?.INRDetails?.WithoutGSTValue +
                                totalTaxAmount
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "57%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                          //   borderRight: "none",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          TDS @1%
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails?.TDS
                                ? MediaSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "71%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={3}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Payment Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              TotalAdditionalCostWtihoutGSTInBXI +
                              TotalPriceWithoutGST -
                              MediaSummaryData?.INRDetails?.TDS
                                ? TotalAdditionalCostWtihoutGSTInBXI +
                                  TotalPriceWithoutGST -
                                  MediaSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Coins
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              MediaSummaryData?.INRDetails?.TotalInrToPay
                                ? MediaSummaryData?.INRDetails?.TotalInrToPay
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Seller to BXI
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {MediaSummaryData?.IsMedia ? (
                            <CommaSeprator
                              Price={
                                MediaSummaryData?.INRDetails?.TotalBxiCommission
                                  ? MediaSummaryData?.INRDetails
                                      ?.TotalBxiCommission +
                                    MediaSummaryData?.INRDetails
                                      ?.BxiCommisionGST +
                                    MediaSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          ) : (
                            <CommaSeprator
                              Price={
                                MediaSummaryData?.INRDetails?.TotalBxiCommission
                                  ? MediaSummaryData?.INRDetails
                                      ?.TotalBxiCommission +
                                    MediaSummaryData?.INRDetails
                                      ?.BxiCommisionGST +
                                    MediaSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  ...elitem,
                  width: "80%",
                  textAlign: "left",
                }}
              >
                <b>NOTE :-</b>
                <br />
                BXI Commission is always charged on Barter Coins.
                <br />
                1% TDS is collected in INR on Total Gross Value.
                <br />
                <img
                  src={BxiCoin}
                  style={{
                    width: "12px",
                    height: "auto",
                  }}
                />{" "}
                - This symbol denotes Barter Coin that can be used only for
                transactions within the BXI marketplace.
                <br />₹ - This symbol denotes the Indian Rupee involved in the
                Transaction.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid
          container
          gap={8}
          sx={{
            py: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xl={11.7}
            lg={11.7}
            md={11.7}
            sm={11.7}
            xs={11.7}
            sx={{
              ...gridstyle,
              width: "578px",
              height: "276px",
            }}
          >
            <Box sx={headbox2}>
              <Typography sx={detailtext}>
                Item Details (Barter Coin Details)
              </Typography>
              <Typography sx={detailtext2}>Details with more info</Typography>
            </Box>

            <Grid
              container
              sx={{
                width: "100%",
                height: "20%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0rem 0rem 0rem 4rem",
                justifyContent: "center",
              }}
            >
              <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                <Typography sx={{ ...headtext, textAlign: "left" }}>
                  No
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <Typography sx={{ ...headtext, textAlign: "left" }}>
                  Item
                </Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={headtext}>Quantity</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <Typography sx={headtext}>Rate / Unit</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <Typography sx={headtext}>Additional Cost</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <Typography sx={headtext}>BXI Coin</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                padding: "1rem 1rem 1rem 4rem",
                justifyContent: "end",
                overflow: "scroll",
              }}
            >
              {MediaSummaryData?.InvoiceData?.ProductData?.map((el, idx) => {
                return (
                  <Grid container sx={{ mt: "10px" }}>
                    <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,
                          textAlign: "left",
                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        {idx + 1}
                      </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,
                          textAlign: "left",
                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        {el.ProductName}
                      </Typography>
                    </Grid>
                    <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,

                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        {el.ProductQuantity}
                      </Typography>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,

                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={
                            el?.ProductTypeName === "Media"
                              ? el?.DiscountedPrice
                              : el?.DiscountedPrice
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,
                          color: "#6B7A99",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          opacity: 1,
                          gap: "5px",
                        }}
                      >
                        <Box
                          component="img"
                          src={StacsOfCoinIcon}
                          sx={{
                            width: "13px",
                            height: "13px",
                          }}
                        />
                        <CommaSeprator
                          Price={
                            el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,
                          color: "#6B7A99",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          opacity: 1,
                          gap: "5px",
                        }}
                      >
                        <Box
                          component="img"
                          src={StacsOfCoinIcon}
                          sx={{
                            width: "13px",
                            height: "13px",
                          }}
                        />

                        <CommaSeprator
                          Price={
                            el?.TotalPriceWithoutGST +
                            el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI
                          }
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Box sx={totaltextbox}>
              <Box sx={totaltextsec}>
                <Typography sx={totaltext}>Total</Typography>
                <Typography
                  sx={{
                    ...totaltext,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <Box
                    component="img"
                    src={StacsOfCoinIcon}
                    sx={{
                      width: "13px",
                      height: "13px",
                    }}
                  />
                  <CommaSeprator
                    Price={
                      MediaSummaryData?.PITotals?.TotalProductCoins +
                      MediaSummaryData?.PITotals
                        ?.TotalAdditionalCostWtihoutGSTInBXI
                    }
                  />
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid> */}
      </Box>
    </Paper>
  );
};

export default MediaOrderSummary;

const headtext = {
  width: "100%",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "15px",
  color: "#6B7A99",
  opacity: 1,
};

const mainbox = {
  width: "100%",
  height: "48px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const headbox = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const contentbox = {
  width: "100%",
  height: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const headbox2 = {
  width: "100%",
  height: "64px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const selername = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  opacity: 0.7,
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const elitem = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const totaltext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  // lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const totaltextbox = {
  width: "100%",
  height: "10%",
  borderTop: "1px solid rgba(149, 144, 168, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const elvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const detailtext = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const detailtext2 = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  opacity: 0.4,
};

const totaltextsec = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const inrvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "10px",
  textAlign: "center",
  color: "#6B7A99",
};

const gridstyle = {
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px 10px 0px 0",
};

const HeaderIconStyle = {
  // border: "1px solid #8C8C8C",
  // borderRadius: "6px",
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  opacity: 0.5,
  color: "#8384a1",
  opacity: 1,
};
