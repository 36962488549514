import React, { useEffect, useRef } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import "./ReleaseLetterStyle.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Signviral from "../../../assets/SignViral.png";
import VectorArrow from "../../../assets/VectorArrow.jpg";
import BXILogo from "../../../assets/BXI_LOGO.png";
import { useParams } from "react-router-dom";
import ProductOrderSummary from "./ProductOrderSummary";
import VoucherOrderSummary from "./VoucherOrderSummary";
import MediaOrderSummary from "./MediaOrderSummary";
import { MdClose } from "react-icons/md";
import GlobalToast from "../../../components/Toasts/GlobalToast";

const BXIReleasePage = ({
  OrderData,
  modalOpen,
  modalState,
  ReleaseEscrowFund,
}) => {
  let paramsdata = useParams();
  const componentRef = useRef(null);
  const OrderSummaryRef = useRef(null);

  let Seller_amount = OrderData?.escrowPayment?.transaction_amount;

  const MakeEscrowPayment = async (PayData) => {
    await axios
      .post("/escrow/release-funds", {
        OrderId: PayData?._id,
      })
      .then((res) => {})
      .catch((err) => {
        window.location.reload();
      });
  };

  async function BXICommisionGenerate(OrderId) {
    await axios
      .post(`commission/create_bxi_commision_invoice`, {
        OrderId: OrderId,
        IsVoucher: false,
      })
      .then(async (res) => {
        if (res?.data?.data?._id) {
          // withdraw_to_bank(OrderId);
          GlobalToast("Transaction Successfull", "success");
        }
        await axios
          .put(`order/update_order_with_any_data/${OrderId}`, {
            BuyerOrderStatus: "success",
          })
          .then((res) => {});
      })
      .catch(() => {});
  }

  const MakeWalletTransaction = async (OrderData) => {
    await axios
      .post(`wallet/release_onhold_transaction`, {
        transaction_id: OrderData?.WalletData?._transaction_id,
        OrderId: OrderData?._id,
      })
      .then(() => {})
      .catch((err) => {
        GlobalToast("Wallet Transaction Release Error", err);
      });
  };

  const MakeEscrow_ReleaseFunds = async () => {
    try {
      if (Seller_amount < 1) {
        GlobalToast("You can't release funds less than 1 INR", "error");
      } else {
        if (!OrderData?.escrowRelease?.ReleaseFundDocId) {
          const contentDiv = componentRef.current;
          const OrderSummaryDiv = OrderSummaryRef.current;
          const canvas = await html2canvas(contentDiv);
          const pdf = new jsPDF();
          pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 290);
          pdf.addPage();
          const canvas2 = await html2canvas(OrderSummaryDiv);
          pdf.addImage(canvas2.toDataURL("image/png"), "PNG", 0, 0, 200, 280);
          const pdfBlob = pdf.output("blob");
          const formData = new FormData();
          formData.append("file", pdfBlob, "document.pdf");
          formData.append("Key", "pdf/");
          formData.append("ContentType", "application/pdf");
          try {
            await axios
              .post("upload/upload", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                axios
                  .post("escrow/upload-relase-fund-doc", {
                    OrderId: paramsdata.id,
                    FileUrl: res?.data.file,
                  })
                  .then((res) => {
                    if (res?.data?.OrderData?.escrowRelease?.ReleaseFundDocId) {
                      setTimeout(() => {
                        MakeEscrowPayment(res?.data?.OrderData);
                      }, 2000);
                    } else {
                      GlobalToast("Facing server down issue", "error");
                    }
                  })
                  .catch((err) => {
                    GlobalToast("Facing server down issue", err);
                  });
              });
          } catch (e) {}
        } else {
          MakeEscrowPayment(OrderData);
        }
      }
    } catch (e) {}
  };

  const ReleaseTheFund = () => {
    if (ReleaseEscrowFund) {
      setTimeout(() => {
        MakeEscrow_ReleaseFunds(OrderData?._id);
      }, 3000);
    }
  };

  const handleModalClose = () => {
    modalState(false);
  };

  useEffect(() => {
    if (
      OrderData?.escrowSellerReleaseResponse?.success &&
      !OrderData?.Wallet_transaction_status
    ) {
      MakeWalletTransaction(OrderData);
    }
    if (
      OrderData?.escrowSellerReleaseResponse?.success &&
      !OrderData?.IsCommisionInvoiceCreated
    ) {
      BXICommisionGenerate(OrderData?._id);
    }
  }, [OrderData]);

  useEffect(() => {
    ReleaseTheFund();
  }, [ReleaseEscrowFund === true]);

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ justifyContent: "center" }}>
          <Box elevation={0} sx={MainBoxStyle}>
            <Box
              sx={{
                width: "100%",
                alignContent: "end",
                justifyContent: "flex-end",
                textAlign: "right",
                height: "60px",
              }}
            >
              <MdClose
                onClick={handleModalClose}
                fontSize="35px"
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              />
            </Box>
            <Box style={{ justifyContent: "center", bgcolor: "white" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "600px",
                  height: "300px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="triple-spinner-two"></div>
                <Typography
                  sx={{
                    color: "#c64091",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: 500,
                    mt: "30px",
                    maxWidth: "70%",
                    mx: "auto",
                    textAlign: "center",
                  }}
                >
                  Please hold on a moment while we ensure your funds are
                  securely transferred into your account.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={ModalBoxStyle}
            style={{ position: "absolute", left: "-9999px" }}
          >
            <div ref={componentRef} id="content-to-convert">
              <Box style={StyleOne}>
                <Box style={StyleTwo}>
                  <Box component={"img"} src={BXILogo} style={StyleThree} />
                  <Box component={"img"} src={VectorArrow} style={StyleFour} />
                </Box>
                <Box class="paper" style={StyleFive}>
                  <Box class="box" style={StyleSix}>
                    <Box class="box" style={StyleSeven}>
                      <Typography sx={CommonFontStyle}>
                        To <br /> <b>ESCROWPAYINDIA.COM,</b>
                        <br />
                        Through <b>(Through BXI)</b>
                        <br />
                        Trust more Technologies (P) Limited
                      </Typography>
                      <Typography sx={CommonFontStyle}>
                        Tower-B, 16th Floor, Emaar Digital Greens, Unit No.
                        017-018, <br /> Golf Course Extension Road, Sector-61,
                        <br /> Gurugram, Haryana-122102
                      </Typography>
                      <Typography sx={CommonFontStyle}>
                        <b>
                          {" "}
                          Sub: Release Letter/Instructions to release “Escrow
                          Funds” from the Escrow Account Ref : Escrow Account
                          No.{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {OrderData?.escrowData?.dealId}{" "}
                          </span>{" "}
                          and
                        </b>
                      </Typography>
                    </Box>
                    <Box class="box" style={StyleEight}>
                      <Typography sx={CommonFontStyle}>
                        Relationship as per Agreement
                      </Typography>
                      <Typography
                        style={{ ...CommonFontStyle, whiteSpace: "nowrap" }}
                      >
                        Name of the Party <br />{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }
                        </span>
                      </Typography>
                      <Typography sx={CommonFontStyle}>
                        Relationship as per Agreement
                      </Typography>
                      <Typography
                        style={{ ...CommonFontStyle, whiteSpace: "nowrap" }}
                      >
                        Name of the Party <br />{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }
                        </span>
                      </Typography>
                    </Box>
                    <Box class="box" style={StyleNine}>
                      <Typography sx={CommonFontStyle}>
                        We, the abovementioned{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }
                        </span>{" "}
                        and the{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {OrderData?.escrowData?.sellerEaaaId}
                        </span>
                        , in the transaction referred to above, hereby state and
                        certify the following:
                      </Typography>
                      <Typography sx={CommonFontStyle}>
                        1. That the Parties Agreement <b> (“Agreement”)</b> was
                        entered between the{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }
                        </span>{" "}
                        and{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </span>
                        to _(Services/business/lending etc.) for provision of
                        goods in the above Escrow Account{" "}
                        <b>{OrderData?.escrowData?.sellerEaaaId}</b>. That as
                        per the Escrow Account Administration Agreement{" "}
                        <b>“EAAA”</b>, BXI is authorized by the{" "}
                        <b>
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }
                        </b>{" "}
                        and
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }
                        </b>{" "}
                        to do various acts, including but not limited to,
                        signing this Release Letter, authorizing Escrow pay to
                        remit the amounts to the respective parties. Pursuant to
                        the said Agreement, the funds were deposited by the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }
                        </b>{" "}
                        {500} in the above Escrow Account.
                      </Typography>
                    </Box>
                    <Box class="box" style={StyleTen}>
                      <Typography sx={CommonFontStyle}>
                        2. The{" "}
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>{" "}
                        confirms that all payouts to the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        from the Escrow Account shall be made to the following
                        Bank Account of the{" "}
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }
                        </b>
                        :
                      </Typography>
                    </Box>
                    <Box class="box" style={StyleEleven}>
                      <Typography textAlign="center" sx={CommonFontStyle}>
                        <b>
                          Name of the Bank
                          <br />
                          {OrderData?.BankDetails?.IFSC.slice(0, 4)}
                        </b>
                      </Typography>
                      <Typography text-align="center" sx={CommonFontStyle} s>
                        <b>
                          Account No
                          <br />
                          <span style={{ borderTop: "1px solid black" }}>
                            {OrderData?.BankDetails?.AccountNumber}
                          </span>
                        </b>
                      </Typography>
                      <Typography text-align="center" sx={CommonFontStyle}>
                        <b>
                          IFSC Code
                          <br />
                          <span style={{ borderTop: "1px solid black" }}>
                            {OrderData?.BankDetails?.IFSC}
                          </span>
                        </b>
                      </Typography>
                    </Box>
                    <Box
                      class="box"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography sx={CommonFontStyle}>
                        The{" "}
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>{" "}
                        confirms that the above information is true and correct
                        and in case of lapses in submitting the details, Escrow
                        pay shall be at liberty to close escrow account/Virtual
                        collection account(s) of the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        , with a prior notice of 7 days. The BXI further
                        undertakes to inform Escrow pay of any changes to the
                        above, within 7 days of the change occurring and to
                        submit revised details
                      </Typography>
                      <Typography sx={CommonFontStyle}>
                        3. That the{" "}
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>
                        and the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        hereby irrevocably consent, authorize and instruct
                        Escrow pay, as a onetime release instruction, to remit
                        any or all Escrow-Funds to the KYC verified Bank account
                        of respective parties, as per the Agreement.
                      </Typography>

                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Typography
                                sx={{ ...CommonFontStyle, fontSize: "13px" }}
                              >
                                Payment Head
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Typography
                                sx={{ ...CommonFontStyle, fontSize: "13px" }}
                              >
                                Amount to be Paid (INR)
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Typography
                                sx={{ ...CommonFontStyle, fontSize: "12px" }}
                              >
                                Payment to Seller
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Typography
                                sx={{ ...CommonFontStyle, fontSize: "12px" }}
                              >
                                {OrderData?.INRDetails?.TotalInrToPay?.toFixed(
                                  2
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Typography sx={CommonFontStyle}>
                        4. That the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>
                        and the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        hereby certify and confirm that this Release Letter is
                        being executed by the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>
                        and the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        , as per the terms of the EAAA and/ Master Agreement ,
                        without any coercion or undue influence. Further, the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>
                        and the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        confirm that post remittance of the above payments, the
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }{" "}
                        </b>
                        and the{" "}
                        <b>
                          {" "}
                          {
                            OrderData?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }{" "}
                        </b>
                        waive off any and all claims on the Escrow-Funds.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box class="signatures" style={StyleTwelve}>
                  <Box class="company-signature">
                    <b>BXI </b>Signature and Stamp
                  </Box>
                </Box>
                <Box class="place-date" style={{ marginTop: "20px" }}>
                  <b>
                    Place: Mumbai
                    <br />
                    Date: {new Date().toDateString()}
                  </b>
                </Box>
                <Box class="grid" style={StyleThirteen}>
                  <Box class="signature-container" style={StyleFourteen}>
                    <img
                      src={VectorArrow}
                      alt="Arrow Image"
                      style={StyleFifteen}
                    />

                    <img
                      src={Signviral}
                      alt="Signature Image"
                      style={{
                        position: "absolute",
                        height: "180px",
                        width: "auto",
                        right: "5%",
                        top: "-40%",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
            <div ref={OrderSummaryRef}>
              {OrderData?.IsMedia ? (
                <MediaOrderSummary MediaSummaryData={OrderData} />
              ) : null}
              {OrderData?.IsVoucher ? (
                <VoucherOrderSummary VoucherSummaryData={OrderData} />
              ) : null}
              {!OrderData?.IsVoucher && !OrderData?.IsMedia ? (
                <ProductOrderSummary ProductSummaryData={OrderData} />
              ) : null}
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BXIReleasePage;

const CommonFontStyle = {
  fontFamily: "Poppins",
  fontSize: "10px",
};

const StyleOne = {
  maxWidth: "768px",
  margin: "0 auto",
  padding: "16px",
  border: "1px solid #ccc",
  fontFamily: "Poppins",
  fontSize: "small",
};

const StyleTwo = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "150px",
  position: "relative",
};

const StyleThree = { height: "100px", width: "auto" };

const StyleFour = {
  transform: "rotate(180deg)",
  height: "170px",
  opacity: "0.4",
  width: "auto",
  marginRight: "-17px",
};

const StyleFive = {
  elevation: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: "10px",
};

const StyleSix = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "80%",
};

const StyleSeven = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const StyleEight = { display: "flex", flexDirection: "row", gap: "5%" };

const StyleNine = {
  display: "flex",
  flexDirection: "column",
  gap: "10%",
};

const StyleTen = { display: "flex", flexDirection: "row", gap: "10%" };

const StyleEleven = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "10%",
};

const StyleTwelve = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginTop: "20px",
};

const StyleThirteen = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "space-between",
  width: "100%",
  marginTop: "5%",
};

const StyleFourteen = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  width: "100%",
  position: "relative",
};

const StyleFifteen = {
  height: "170px",
  width: "auto",
  opacity: "0.4",
  marginLeft: "-20px",
};

const MainBoxStyle = {
  width: "400px",
  height: "300px",
  display: "grid",
  bgcolor: "#fff",
  top: "50%",
  left: "50%",
  maxWidth: "400px",
  position: "absolute",
  zIndex: 1000,
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
};

const ModalBoxStyle = {
  height: "100%",
  maxHeight: "90vh",
  width: "100%",
  maxWidth: "50vw",
  transform: "translate(-50%, -50%)",
  top: "50%",
  left: "50%",
  position: "absolute",
  overflow: "scroll",
  bgcolor: "white",
};

const BoxLoaderBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "300px",
  backgroundColor: "white",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const IncaseOfButton = {
  backgroundColor: "#FF5C93",
  color: "white",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  borderRadius: "10px",
  padding: "10px 20px",
  mt: "20px",
  ":hover": {
    backgroundColor: "#FF5C93",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0 0 )",
    color: "white",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "10px",
    padding: "10px 20px",
    mt: "20px",
    cursor: "pointer",
  },
};
