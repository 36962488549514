import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import useStyles from "./Styles";
import EmptyCart from "./EmptyCart";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { BiCheckbox } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import sendEvents from "../../utils/sendEvents";
import CommaSeprator from "../../components/CommaSeprator";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import { get_Cart_Items } from "../../redux/action/CartActions";
import * as Styles from "../../components/common/Styled/Styles.js";
import addItemCartIcon from "../../assets/CartPage/addItemIcon.svg";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import TrashCanIcon from "../../assets/WishlistPage/TrashCanicon.svg";
import {
  useGetCartData,
  useGetMediaCartData,
} from "../../Hooks/ProductActions/useGetCartData";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import { useCreateMediaOrder } from "../../Hooks/MediaHooks/useCreateMediaOrder";
import { ProfileCompletions } from "../../redux/action/Profile/Profile-Completion";
import { useRemoveMediaFromCart } from "../../Hooks/MediaHooks/useRemoveMediaFromCart";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";
import { useEffectOnce } from "react-use";
const MediaCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [balance, setBalance] = useState("");
  const [Order, setOrder] = useState([]);
  const [MediaCartLoading, setMediaCartLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [ProfilePercentage, setProfilePercentage] = useState(0);
  const [CartRemoveData, setCartRemoveData] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    data: MediaCartData,
    refetch: cartRefetch,
    isLoading: mediaCartLoading,
  } = useGetMediaCartData();
  const classes = useStyles();

  useEffectOnce(() => {
    setOrder(MediaCartData?.body);
  }, []);

  function storeData(params) {
    const orderData = Order.find((el) => el._id === params._id);
    if (orderData) {
    } else {
      setOrder([...Order, params]);
    }
  }

  const { ProfileCompletion, loading } = useSelector(
    (state) => state.profileCompletion
  );

  let findPercentage =
    typeof ProfileCompletion?.ProfileMessage === "string"
      ? ProfileCompletion?.ProfileMessage.split(" ")
      : null;

  let finalPercentage =
    findPercentage && findPercentage.length >= 4
      ? findPercentage[3]?.toString()?.split(".")
      : null;

  // media cart order
  const {
    data: MediaOrderCreate,
    isLoading: MediaOrderLoading,
    error: MediaOrderError,
    mutate: MediaOrderMutate,
  } = useCreateMediaOrder();

  // remover cart api fetch here
  function removeData(params) {
    const orderData = Order.find((el) => el._id === params._id);
    if (orderData) {
      const newOrder = Order.filter((el) => el._id !== params._id);
      setOrder(newOrder);
    } else {
    }
  }

  // here we GET wallet balance
  const reqBalance = async () => {
    await axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.body);
      });

    await axios
      .get(
        "auth/profilecompletion",
        {
          withCredentials: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let findPercentage =
          typeof res?.data?.ProfileMessage === "string"
            ? res?.data?.ProfileMessage.split(" ")
            : null;

        let finalPercentage =
          findPercentage && findPercentage.length >= 4
            ? findPercentage[3]?.toString()?.split(".")
            : null;
        setProfilePercentage(finalPercentage[0]);
      })
      .then(() => {
        dispatch(ProfileCompletions(ProfilePercentage));
      })
      .catch((err) => {});
  };
  useEffect(() => {
    reqBalance();
  }, []);

  // inital all value set up here
  let totalPrice = 0;
  let totalgst = 0;
  let totalQuantity = 0;
  let dataId = [];
  let totalqty = 0;
  let TotalAdditionalCostInRupee = 0;
  let TotalAdditionalCostInBXI = 0;
  let ToTalGstPercentage = 0;
  let totalfinalPrice = 0;
  let BoughtSeconds = 0;
  let TotalGSTAmount = 0;

  let TotalPriceWithoutGSt = 0;

  let DataToBeSentToBackend = [];

  Order?.map((el) => {
    DataToBeSentToBackend.push({
      MediaCartId: el?._id,
      ProductId: el?.ProductId?._id,
      SellerCompanyId: el?.ProductId?.SellerCompanyId,
    });
    TotalPriceWithoutGSt += el?.TotalMediaCoins;
    BoughtSeconds += el?.BoughtSeconds;
    ToTalGstPercentage += el?.ProductGst;
    totalPrice += el?.DiscountedPrice;
    totalgst +=
      (el?.DiscountedPrice *
        el?.VoucherQuantity *
        el?.BoughtSeconds *
        el?.TimeLineToBought *
        el?.ProductGst) /
      100;
    TotalGSTAmount += el?.TotalGST;
    totalfinalPrice += el?.TotalFinalMediaPrice;
    totalQuantity = totalQuantity + el.NumberOfDays;
    TotalAdditionalCostInRupee += el?.AdditionCost.TotalAdditionalCostInRupee;
    TotalAdditionalCostInBXI += el?.AdditionCost?.TotalAdditionalCostInBXI;
    totalqty += el.VoucherQuantity;
    dataId.push(el._id);
  });
  // remover cart api fetch here
  const { mutate: removeCartProduct, refetch: removeCartProductRefetch } =
    useRemoveMediaFromCart();

  useEffect(() => {
    if (MediaOrderCreate?.data === "Order Created") {
      toast.success("Order Created. Redirecting to next page", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate(`/home/mediapurchaseorder`);
      }, 2000);
      handleRemoveCartplaceorder();
    } else if (MediaOrderCreate?.data === "OrderSummary validation failed") {
      toast.error("OrderSummary validation failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [MediaOrderCreate]);

  async function handleRemoveCartProduct(id) {
    await removeCartProduct(id);
    await setOpen(false);
  }
  useEffect(() => {
    cartRefetch();
  }, [removeCartProductRefetch]);
  async function handleRemoveMultipleCartProduct() {
    dataId.map((el) => {
      removeCartProduct(el);
      // GetMediaCartData();
      cartRefetch();
    });
    setOrder([]);
    // GetMediaCartData();
    cartRefetch();
  }
  async function handleRemoveCartplaceorder() {
    dataId.map((el) => {
      removeCartProduct(el);
      // GetMediaCartData();
      cartRefetch();
    });
    setOrder([]);
    // GetMediaCartData();
    cartRefetch();
  }

  // create order before check all condition
  async function handleCreateOrder() {
    if (ProfileCompletion) {
      if (finalPercentage && finalPercentage[0] >= 100) {
        MediaOrderMutate(DataToBeSentToBackend);
      } else {
        GlobalToast(
          "100% Profile Completion is Mandatory to Start Trading. It is important for business documents.",
          "error"
        );
        navigate("/home/profile");
      }
    } else {
      alert("Please wait while we check your profile before creating Order ");
    }

    cartRefetch();
  }

  useEffect(() => {
    dispatch(get_Cart_Items());
  }, []);

  return (
    <Paper sx={{ bgcolor: "transparent" }} elevation={0}>
      {MediaCartData?.body?.length <= 0 ? (
        mediaCartLoading ? (
          "Loading ...."
        ) : (
          <EmptyCart />
        )
      ) : (
        <React.Fragment>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {MediaCartData?.body?.map((el, idx) => {
              return (
                <Box key={idx}>
                  <Box className={classes.rootbox}>
                    <Box className={classes.rootboxChildOne}>
                      <Box
                        className={classes.cartProductStrip}
                        sx={{
                          backgroundImage: `url(${el?.ProductId?.ProductImages[0].url})`,
                        }}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          alignContent: "start",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <Typography sx={Styles.ProductNameTextStyle}>
                          {el?.ProductId?.ProductName}
                        </Typography>
                        <Typography
                          sx={{
                            ...Styles.ProductNameTextStyle,
                            fontSize: "11px",
                          }}
                        >
                          {el?.ProductId?.ProductDescription}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={Styles.ProductPriceTextStyle}>
                            <img
                              src={StacsOfCoinIcon}
                              alt="rupieicon"
                              style={{
                                width: "17px",
                                height: "auto",
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              ...Styles.ProductPriceTextStyle,
                              marginTop: "-03px",
                            }}
                          >
                            &nbsp;
                            <CommaSeprator Price={el?.DiscountedPrice} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "120px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      {dataId.includes(el._id) ? (
                        <Box
                          onClick={() => {
                            removeData(el);
                            sendEvents("Click on add item to cart");
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <img src={addItemCartIcon} size={30} />
                        </Box>
                      ) : (
                        <Box
                          onClick={() => {
                            storeData(el);
                            sendEvents("Click on checkbox");
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <BiCheckbox size={30} />
                        </Box>
                      )}

                      <Box
                        onClick={() => {
                          handleClickOpen();
                          setCartRemoveData({
                            id: el._id,
                            ProductName: el?.ProductId?.ProductName,
                          });
                          removeData(el);
                          sendEvents("Click on close button");
                        }}
                      >
                        <IoClose
                          size={25}
                          style={{
                            cursor: "pointer",
                          }}
                          sx={{
                            color: "rgba(104, 107, 111, 1)",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={Styles.GridStyle}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
                bgcolor: "transparent",
                justifyContent: "flex-start",
              }}
            >
              {" "}
              <Box
                component={"img"}
                src={addItemCartIcon}
                alt="icon"
                style={{ cursor: "pointer" }}
              ></Box>
              <Typography
                sx={{ ...Styles.cartSelectionTextStyle, display: "flex " }}
              >
                {Order?.length}/{MediaCartData?.length}
                &nbsp;Items&nbsp;Selected&nbsp; (&nbsp;
                {totalPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={StacsOfCoinIcon}
                      style={{
                        width: "auto",
                        height: "23px",
                        marginRight: "5px",
                      }}
                      alt="rupieicon"
                    />
                    <Typography sx={Styles.cartSelectionTextStyle}>
                      <CommaSeprator
                        Price={TotalPriceWithoutGSt + TotalAdditionalCostInBXI}
                      />{" "}
                      + ₹ &nbsp;
                      <CommaSeprator
                        Price={TotalGSTAmount + TotalAdditionalCostInRupee}
                      />
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={StacsOfCoinIcon}
                      style={{
                        width: "23px",
                        height: "auto",
                      }}
                      alt="rupieicon"
                    />
                    <Typography sx={Styles.cartSelectionTextStyle}>
                      0
                    </Typography>
                  </Box>
                )}
                &nbsp;)
                {"   "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "2rem" }}>
              {" "}
              <Box
                component={"img"}
                src={TrashCanIcon}
                alt="trashIcon"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  let confirm = window.confirm(
                    "Are you sure you want to delete selected items from cart?"
                  );
                  if (confirm === true) {
                    handleRemoveMultipleCartProduct();
                  } else {
                    return;
                  }
                  sendEvents("Click on trash");
                }}
              ></Box>
            </Box>
          </Grid>

          {Order?.length === 0 ? null : (
            <React.Fragment>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  background: "#fff",
                  padding: "4rem",
                  borderRadius: "10px",

                  marginTop: "2rem",
                }}
              >
                <Typography sx={{ ...Styles.cartSelectionTextStyle }}>
                  PRICE DETAILS&nbsp;({Order?.length} Items){"   "}
                </Typography>

                <TableContainer sx={{ marginTop: "3rem", width: "100%" }}>
                  <TableHead>
                    <TableRow sx={{ height: "42px", bgcolor: "#2261A2" }}>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD", width: "50px" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          {" "}
                          S. No.
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={2}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Product Name
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Price / Unit{" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Quantity
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Timeline To Buy
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          GST(%)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Total Price (Excluding GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          GST Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Additional Cost INR (INC GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Additional Cost Barter Coins (INC GST)
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          bgcolor: "#2261A2",
                          width: "170px",
                          borderLeft: "1px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Grand Total
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Order?.map((row, idx) => {
                      let AdCostInInr = 0;
                      let AdCostInBXI = 0;
                      row?.AdditionCostArrayData?.map((item) => {
                        if (item?.currencyType === "₹") {
                          AdCostInInr = AdCostInInr + item?.TotalWithGst;
                        } else {
                          AdCostInBXI = AdCostInBXI + item?.TotalWithGst;
                        }
                      });

                      return (
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderLeft: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {idx + 1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductId?.ProductName} <br></br>
                              <span>
                                {row?.BoughtSeconds > 1
                                  ? "(" +
                                    row?.BoughtSeconds * 10 +
                                    ")" +
                                    " Seconds"
                                  : null}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.DiscountedPrice} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {row?.VoucherQuantity}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {row?.TimeLineToBought} ({row?.TimeLine})
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {row?.ProductGst}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.TotalMediaCoins} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.TotalGST} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {" "}
                              <CommaSeprator
                                Price={
                                  row?.AdditionCost?.TotalAdditionalCostInRupee
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator
                                Price={
                                  row?.AdditionCost?.TotalAdditionalCostInBXI
                                }
                              />
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalFinalMediaPrice}
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow
                      sx={{
                        height: "42px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={3}
                        rowSpan={3}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderLeft: "1px solid #CDCDCD",
                          background: "#2261a2",
                        }}
                      >
                        <Typography
                          sx={{ ...Styles.TableBottomtext, color: "#fff" }}
                        >
                          Total
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>-</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          {totalQuantity}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>-</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalPriceWithoutGSt} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalGSTAmount} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalAdditionalCostInRupee} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalAdditionalCostInBXI} />
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={totalfinalPrice} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
                <Button
                  sx={Styles.CreateOrderButtonStyle}
                  onClick={() => {
                    handleCreateOrder();
                    sendEvents("Click on generate purchase order");
                  }}
                >
                  Generate Purchase Order
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {CartRemoveData?.ProductName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item from cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              sendEvents("Click on close");
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemoveCartProduct(CartRemoveData?.id);
              sendEvents("Click on remove");
            }}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
export default MediaCart;
