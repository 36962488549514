import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Input,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import useStyles from "./Styles";
import EmptyCart from "./EmptyCart";
import { IoClose } from "react-icons/io5";
import { BiCheckbox } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import sendEvents from "../../utils/sendEvents";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CommaSeprator from "../../components/CommaSeprator";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import { get_Cart_Items } from "../../redux/action/CartActions";
import * as Styles from "../../components/common/Styled/Styles.js";
import addItemCartIcon from "../../assets/CartPage/addItemIcon.svg";
import TrashCanIcon from "../../assets/WishlistPage/TrashCanicon.svg";
import { useCreatePrchaseOrder } from "../../Hooks/OrderActions/useCreatePrchaseOrder";
import { useGetVoucherCartData } from "../../Hooks/ProductActions/useGetCartData";
import {
  useRemoveCartProduct,
  useRemoveVoucherCartProduct,
} from "../../Hooks/ProductActions/useRemoveCartProduct";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";
import { useEffectOnce } from "react-use";

const VoucherCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [balance, setBalance] = React.useState("");
  const [Order, setOrder] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [EditItem, setEditItem] = useState(null);
  const [Itemqty, setItemQty] = useState(0);
  const [AllVoucher, setAllVoucher] = useState([]);
  const [ProfilePercentage, setProfilePercentage] = useState(0);
  const [CartRemoveData, setCartRemoveData] = useState({});
  const [OutOfStockProducts, setOutOfStockProducts] = useState([]);
  const [isButtonHidden, setButtonHidden] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // get all voucher here
  const { data: VoucherCartData, refetch: VoucherCartRefetch } =
    useGetVoucherCartData();
  const classes = useStyles();

  useEffectOnce(() => {
    setOrder(VoucherCartData);
  }, []);

  function storeData(params) {
    const orderData = Order?.find((el) => el._id === params._id);
    if (orderData) {
    } else {
      setOrder([...Order, params]);
    }
  }
  const { ProfileCompletion, loading } = useSelector(
    (state) => state.profileCompletion
  );

  function removeData(params) {
    const orderData = Order?.find((el) => el._id === params._id);
    if (orderData) {
      const newOrder = Order?.filter((el) => el._id !== params._id);
      setOrder(newOrder);
    } else {
    }
  }

  const reqBalance = async () => {
    await axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.body);
      });
    await axios
      .get(
        "auth/profilecompletion",
        {
          withCredentials: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let findPercentage =
          typeof res?.data?.ProfileMessage === "string"
            ? res?.data?.ProfileMessage.split(" ")
            : null;

        let finalPercentage =
          findPercentage && findPercentage.length >= 4
            ? findPercentage[3]?.toString()?.split(".")
            : null;
        setProfilePercentage(finalPercentage[0]);
      });
  };

  useEffect(() => {
    reqBalance();
  }, []);

  // inital all value set up here
  let totalPrice = 0;
  let Totalgst = 0;
  let totalQuantity = 0;
  let dataId = [];
  let TotalGSTAmount = 0;
  let TotalAdditionalCostInRupee = 0;
  let TotalAdditionalCostInBXI = 0;
  let ToTalGstPercentage = 0;
  let TotalfinalPrice = 0;
  let TotalQuantity = 0;
  let TotalPriceExcludingGST = 0;
  let GrandTotal = 0;

  let ObjectForBuy = [];

  Order?.map((el) => {
    ObjectForBuy.push({
      ProductId: el.ProductId._id,
      SellerCompanyId: el.ProductId.SellerCompanyId,
      ProductsVariantionId: el.ProductsVariantionId,
    });
    GrandTotal += el?.TotalPriceWithGST;
    TotalPriceExcludingGST += el?.TotalPriceWithoutGST;
    TotalGSTAmount = TotalGSTAmount + el.TotalGSTAmount;
    TotalQuantity = TotalQuantity + el.VoucherQuantity;
    ToTalGstPercentage += el?.ProductGst;
    totalPrice = totalPrice + el.DiscountedPrice;
    Totalgst += el?.TotalGST;
    totalQuantity = totalQuantity + el.VoucherQuantity;
    TotalAdditionalCostInRupee +=
      el?.AdditionalCost?.TotalAdditionalCostInRupee;
    TotalAdditionalCostInBXI += el?.AdditionalCost?.TotalAdditionalCostInBXI;
    dataId.push(el._id);
  });

  TotalfinalPrice =
    GrandTotal +
    Totalgst +
    TotalAdditionalCostInRupee +
    TotalAdditionalCostInBXI;

  const { mutate: removeCartProduct, refetch: removeCartProductRefetch } =
    useRemoveCartProduct();

  // remover cart api fetch here
  const { mutate: removeVoucherCartProduct, data: VoucherCartadata } =
    useRemoveVoucherCartProduct();

  const { data: mutatedata, mutate: createOrder } = useCreatePrchaseOrder();

  useEffect(() => {
    if (mutatedata?.data === "OrderSummary validation failed") {
      toast.error("OrderSummary validation failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [mutatedata]);

  // remove voucer cart by id
  async function handleRemoveVoucherfromCart(id) {
    removeVoucherCartProduct(id);
    VoucherCartRefetch();
    setOpen(false);
  }

  useEffect(() => {
    VoucherCartRefetch();
  }, [VoucherCartadata]);

  // multipl product remove here
  async function handleRemoveMultipleCartProduct() {
    dataId.map((el) => {
      removeVoucherCartProduct(el);
    });
    setOrder([]);
  }

  async function handleRemoveCartplaceorder() {
    dataId.map((el) => {
      removeVoucherCartProduct(el);
    });
    setOrder([]);
  }

  // create order before check all condition
  async function handleCreateOrder() {
    if (ProfileCompletion) {
      if (ProfilePercentage && ProfilePercentage >= 100) {
        await axios
          .post(
            "voucher/buy_voucher",
            { ObjectForBuy },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (res?.data === "Order Created") {
              handleRemoveCartplaceorder();
              toast.success("Order Created. Redirecting to next page", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate("/home/voucherorderlist");
              }, 2000);
            }
          })
          .catch((err) => {});
        // }
      } else {
        GlobalToast(
          "100% Profile Completion is Mandatory to Start Trading. It is important for business documents.",
          "error"
        );
        navigate("/home/profile");
      }
    } else {
      alert("Please wait while we check your profile before creating Order ");
    }
  }

  useEffect(() => {
    dispatch(get_Cart_Items());
  }, []);

  const updateVoucher = async (el) => {
    return await axios
      .post("voucher/update_voucher_cart_qty", {
        cartid: el._id,
        NewQty: Itemqty,
        productId: el?.ProductId,
        productVariationId: el?.ProductsVariantionId,
      })
      .then((res) => {
        VoucherCartRefetch();
        setOrder([]);
        setEditItem(null);
      })
      .catch((err) => {
        toast.success(res.data);
        toast.error(err.response.data);
      });
  };

  useEffect(() => {
    if (AllVoucher && VoucherCartData) {
      const outOfStockItems = [];

      for (let product of AllVoucher) {
        for (let card in VoucherCartData) {
          const cardItem = VoucherCartData[card];

          if (
            product?.ProductsVariantions?.[0]?.MaxOrderQuantity === 0 &&
            product?._id === cardItem?.ProductId?._id
          ) {
            outOfStockItems.push(cardItem);
          }
        }
      }

      setOutOfStockProducts(outOfStockItems);
    }
  }, [AllVoucher]);

  return (
    <Paper sx={{ bgcolor: "transparent" }} elevation={0}>
      <ToastContainer style={{ fontSize: "16px" }} />

      {VoucherCartData?.length > 0 ? (
        <React.Fragment>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {VoucherCartData?.map((el, idx) => {
              let ProductVariation = el?.ProductId?.ProductsVariantions.find(
                (item) => {
                  return el.ProductsVariantionId === item._id;
                }
              );
              let maxOrderQuantity = ProductVariation.MaxOrderQuantity;
              let minOrderQuantity = ProductVariation.MinOrderQuantity;

              const isOutOfStock = OutOfStockProducts.some(
                (item) => item.ProductId?._id === el.ProductId?._id
              );
              return (
                <Box key={idx}>
                  <Box className={classes.rootbox}>
                    <Box className={classes.rootboxChildOne}>
                      <Box
                        className={classes.cartProductStrip}
                        sx={{
                          backgroundImage: `url(${el?.VoucherUImage})`,
                        }}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          alignContent: "start",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <Typography sx={Styles.ProductNameTextStyle}>
                          {el?.ProductId?.ProductName}
                        </Typography>
                        <Typography
                          sx={{
                            ...Styles.ProductNameTextStyle,
                            fontSize: "11px",
                          }}
                        >
                          {el?.ProductId?.ProductDescription}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={Styles.ProductPriceTextStyle}>
                            <img
                              src={StacsOfCoinIcon}
                              alt="rupieicon"
                              style={{
                                width: "17px",
                                height: "auto",
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              ...Styles.ProductPriceTextStyle,
                              marginTop: "-03px",
                            }}
                          >
                            &nbsp;
                            <CommaSeprator Price={el?.PricePerUnit} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box className={classes.rootboxChildOne}>
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        Min :{minOrderQuantity}
                      </Typography>
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        Max :{maxOrderQuantity}
                      </Typography>
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        QTY :{el?.VoucherQuantity}
                      </Typography>
                      {isButtonHidden ? null : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setEditItem(el._id);
                            setItemQty(el?.VoucherQuantity);
                            setButtonHidden(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {EditItem === el._id ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={btnDesign}
                            onClick={() => {
                              if (maxOrderQuantity <= Itemqty) {
                                return;
                              }
                              setItemQty(Itemqty + 1);
                            }}
                          >
                            +
                          </Box>
                          <Input
                            value={Number(Itemqty)}
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            placeholder="Password"
                            sx={{
                              width: "auto",
                              maxWidth: "50px",
                              height: "38px",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              background: "#FFFFFF",
                              borderRadius: "10px",
                              fontSize: "10px",
                              color: "#445FD2",
                              border: "1px solid rgba(209, 209, 209, 1)",
                            }}
                            inputProps={{
                              style: {
                                color: "#445FD2",
                                fontSize: "14px",
                                padding: "5px",
                                paddingLeft: "5px",
                                textAlign: "center",
                              },
                            }}
                          />
                          <Box
                            sx={btnDesign}
                            variant="outlined"
                            onClick={() => {
                              if (minOrderQuantity >= Itemqty) {
                                return;
                              }
                              setItemQty(Itemqty - 1);
                            }}
                          >
                            -
                          </Box>
                          <Button
                            variant="contained"
                            onClick={async () => {
                              await axios
                                .post("voucher/update_voucher_cart_qty", {
                                  cartid: el._id,
                                  NewQty: Itemqty,
                                  productId: el?.ProductId,
                                  productVariationId: el?.ProductsVariantionId,
                                })
                                .then((res) => {
                                  VoucherCartRefetch();
                                  setOrder([]);
                                  toast.success(res?.data?.message);
                                  setEditItem(null);
                                  setButtonHidden(false);
                                })
                                .catch((err) => {
                                  toast.error(err.response.data);
                                });
                            }}
                          >
                            Confirm
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setEditItem(null);
                              setButtonHidden(false);
                            }}
                          >
                            cancel
                          </Button>
                        </Box>
                      ) : null}
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "200px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          ...Styles.ProductPriceTextStyle,
                          fontSize: "14px",
                          color: "#6B7A99",
                        }}
                      >
                        Click and Proceed
                      </Typography>
                      {isOutOfStock ? (
                        <Typography sx={{ color: "red" }}>
                          Out of Stock
                        </Typography>
                      ) : dataId.includes(el._id) ? (
                        <Box
                          onClick={() => {
                            removeData(el);
                            sendEvents("Click on add item to cart");
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <img src={addItemCartIcon} size={30} />
                        </Box>
                      ) : (
                        <Box
                          onClick={() => {
                            storeData(el);
                            sendEvents("Click on checkbox");
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <BiCheckbox size={30} />
                        </Box>
                      )}

                      <Box
                        onClick={() => {
                          handleClickOpen();
                          setCartRemoveData({
                            id: el._id,
                            ProductName: el?.ProductId?.ProductName,
                          });
                          // removeVoucherCartProductRefetch();
                          sendEvents("Click on close button");
                        }}
                      >
                        <IoClose
                          size={25}
                          style={{
                            cursor: "pointer",
                          }}
                          sx={{
                            color: "rgba(104, 107, 111, 1)",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={Styles.GridStyle}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
                bgcolor: "transparent",
                justifyContent: "flex-start",
              }}
            >
              {" "}
              <Box
                component={"img"}
                src={addItemCartIcon}
                alt="icon"
                style={{ cursor: "pointer" }}
              ></Box>
              <Typography
                sx={{ ...Styles.cartSelectionTextStyle, display: "flex " }}
              >
                {Order?.length}/{VoucherCartData?.length}
                &nbsp;Items&nbsp;Selected&nbsp; (&nbsp;
                {totalPrice ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={StacsOfCoinIcon}
                      style={{
                        width: "auto",
                        height: "23px",
                        marginRight: "5px",
                      }}
                      alt="rupieicon"
                    />
                    <Typography sx={Styles.cartSelectionTextStyle}>
                      <CommaSeprator
                        Price={
                          TotalPriceExcludingGST + TotalAdditionalCostInBXI
                        }
                      />{" "}
                      + ₹ &nbsp;
                      <CommaSeprator
                        Price={Totalgst + TotalAdditionalCostInRupee}
                      />
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={StacsOfCoinIcon}
                      style={{
                        width: "23px",
                        height: "auto",
                      }}
                      alt="rupieicon"
                    />
                    <Typography sx={Styles.cartSelectionTextStyle}>
                      0
                    </Typography>
                  </Box>
                )}
                &nbsp;)
                {"   "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "2rem" }}>
              {" "}
              <Box
                component={"img"}
                src={TrashCanIcon}
                alt="trashIcon"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  let confirm = window.confirm(
                    "Are you sure you want to delete selected items from cart?"
                  );
                  if (confirm === true) {
                    handleRemoveMultipleCartProduct();
                  } else {
                    return;
                  }
                  sendEvents("Click on trash");
                }}
              ></Box>
            </Box>
          </Grid>
          {Order?.length === 0 ? null : (
            <React.Fragment>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  background: "#fff",
                  padding: "4rem",
                  borderRadius: "10px",

                  marginTop: "2rem",
                }}
              >
                <Typography sx={{ ...Styles.cartSelectionTextStyle }}>
                  PRICE DETAILS&nbsp;({Order?.length} Items){"   "}
                </Typography>
                <TableContainer sx={{ marginTop: "3rem", width: "100%" }}>
                  <TableHead>
                    <TableRow sx={{ height: "42px", bgcolor: "#2261A2" }}>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD", width: "50px" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          {" "}
                          S. No.
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={2}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Product Name
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Price / Unit{" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Quantity
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          GST(%)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Total Price (Excluding GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          GST Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Additional Cost INR (INC GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Additional Cost Barter Coins (INC GST)
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          bgcolor: "#2261A2",
                          width: "170px",
                          borderLeft: "1px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={Styles.TableTextStyle}>
                          Grand Total
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Order?.map((row, idx) => {
                      let AdCostInInr = 0;
                      let AdCostInBXI = 0;
                      row?.AdditionCostArrayData?.map((item) => {
                        if (item?.currencyType === "₹") {
                          AdCostInInr = AdCostInInr + item?.TotalWithGst;
                        } else {
                          AdCostInBXI = AdCostInBXI + item?.TotalWithGst;
                        }
                      });

                      return (
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderLeft: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {idx + 1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductId?.ProductName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.PricePerUnit} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.VoucherQuantity} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.ProductGst} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator Price={row?.TotalGST} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              {" "}
                              <CommaSeprator
                                Price={
                                  row?.AdditionalCost
                                    ?.TotalAdditionalCostInRupee
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator
                                Price={
                                  row?.AdditionalCost?.TotalAdditionalCostInBXI
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={Styles.TableBottomtext}>
                              <CommaSeprator
                                Price={
                                  row?.TotalPriceWithGST +
                                  row?.AdditionalCost
                                    ?.TotalAdditionalCostInRupee +
                                  row?.AdditionalCost?.TotalAdditionalCostInBXI
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow
                      sx={{
                        height: "42px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={3}
                        rowSpan={3}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderLeft: "1px solid #CDCDCD",
                          bgcolor: "#2261A2",
                        }}
                      >
                        <Typography
                          sx={{ ...Styles.TableBottomtext, color: "#fff" }}
                        >
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalQuantity} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>-</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalPriceExcludingGST} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={Totalgst} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalAdditionalCostInRupee} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator Price={TotalAdditionalCostInBXI} />
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={Styles.TableBottomtext}>
                          <CommaSeprator
                            Price={
                              Totalgst +
                              TotalPriceExcludingGST +
                              TotalAdditionalCostInRupee +
                              TotalAdditionalCostInBXI
                            }
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
                <Button
                  sx={Styles.CreateOrderButtonStyle}
                  onClick={() => {
                    handleCreateOrder();
                    sendEvents("Click on place order");
                  }}
                >
                  Place Order
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <EmptyCart />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {CartRemoveData?.ProductName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item from cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              sendEvents("Click on close");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemoveVoucherfromCart(CartRemoveData?.id);
              sendEvents("Click on remove");
            }}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
export default VoucherCart;

const btnDesign = {
  border: "0.5px solid #445FD2",
  borderRadius: "50%",
  p: 1,
  width: "15px",
  height: "15px",
  fontSize: "15px",
  color: "#445FD2",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  textAlign: "center",
  "&:hover": {
    background: "#445FD2",
    color: "#fff",
  },
};
