import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Box,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../assets/Images/CloseIcon.svg";
import SenderLogo from "../../assets/Images/SenderLogo.png";
import "./BarterGPTModal.css";
import axios from "axios";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import { useBarterGptChatHistory } from "../../Hooks/BarterGptChatHistory/BarterGptChatHistory.js";
import { useForm } from "react-hook-form";
import {
  useGetWantList,
  useAddToWantList,
  useRemoveWantList,
  useGetSubCategory,
} from "../../Hooks/WantList/WantList.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import * as Styles from "../../components/common/Styled/Styles.js";

function BarterGPTModal(props) {
  const { data: AuthUserData } = useGetAuthUser();

  const [messages, setMessages] = useState([
    {
      message: `Hello ${AuthUserData?.data?.name}, It's a pleasure to be of service. How may I assist you today?`,
      sentTime: "just now",
      sender: "name",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  let countnumber = 0;

  const {
    mutate: storeChat,
    loading: storeChatLoading,
    error: storeChatError,
    refetch: storeChatRefetch,
  } = useBarterGptChatHistory();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        productName: z.string().min(1),
        category: z.string().min(1),
        subCategory: z.string().min(1),
        location: z.string().min(1),
        consumptionPeriod: z.string().min(1),
        qty: z.string().min(1),
        natureofOreder: z.string().min(1),
        consumptionPeriodUnit: z.string().min(1),
        type: z.string().min(1),
        remarks: z.string().min(1),
      })
    ),
  });
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);
  };
  const {
    mutate: addWantListitem,
    isLoading: wantMutateLoading,
    isError: wantMutateError,
  } = useAddToWantList();
  const {
    data: subCategoryData,
    isLoading: subcategoryLoading,
    isError: subCategoryError,
    isRefetching: subCategoryRefetch,
  } = useGetSubCategory(selectedCategory);
  const addWantList = handleSubmit((data) => {
    addWantListitem(data, {
      onSuccess: (res) => {
        handleClose();
        wantListRefetch();
        reset();
      },
      onError: (err) => {},
    });
  });
  const handleUserQueries = (message) => {
    const lowerCaseMessage = message.toLowerCase();
    if (lowerCaseMessage.includes("my name")) {
      return AuthUserData?.data?.name
        ? `Your name is ${AuthUserData.data.name}`
        : `You haven't specified your name.`;
    } else if (lowerCaseMessage.includes("my email")) {
      return AuthUserData?.data?.email
        ? `Your email is ${AuthUserData.data.email}`
        : `You haven't specified your email.`;
    } else if (lowerCaseMessage.includes("my phone number")) {
      return AuthUserData?.data?.phone
        ? `Your phone number is ${AuthUserData.data.phone}`
        : `You haven't specified your phone number.`;
    } else if (lowerCaseMessage.includes("my password")) {
      return `We dont have any access to your password. If you forgot your password you can reset your password.`;
    } else if (lowerCaseMessage.includes("your name")) {
      return `My name is BXI Genie`;
    }
    if (
      lowerCaseMessage.includes("i want") ||
      lowerCaseMessage.includes("i wish") ||
      lowerCaseMessage.includes("do you sell") ||
      lowerCaseMessage.includes("do you have") ||
      lowerCaseMessage.includes("find")
    ) {
      handleOpen();
      return `You can request this product here.`;
    }

    return null;
  };

  async function processMessageToChatGPT(chatMessages, newMessage) {
    try {
      setIsTyping(true);

      const chatResponse = await axios.post(
        "https://chatpdf.unada.in/api/v1/chat-bgpt",
        {
          source_id: "src_8m04pZpcWutny5ihRxsuC",
          messages: [{ content: newMessage.message, role: "user" }],
        }
      );

      const userMessage = `Consider BXI as Barter Exchange of India. Please rephrase this answer by removing any mentions of pdf or doc and don't apologise and don't give any page references and consider BXI as Barter Exchange of India for all references. Also make all responses a maximum of 7 lines. For all discussions put your name as BXI Genie instead of AI assistant. If you don't have an answer simply say that - I currently don't have a response. Please try again later! : "${chatResponse.data.content}"`;

      const gptResponse = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: userMessage }],
          }),
        }
      ).then((res) => {
        return res.json();
      });

      const gptContent = gptResponse.choices[0].message.content;

      // Create a copy of the messages array and add the response to it
      const updatedMessages = [
        ...chatMessages,
        {
          message: gptContent,
          sender: "ChatGPT",
        },
      ];

      // Set the updated array as the new state
      setMessages(updatedMessages);

      // Set isTyping to false after receiving the response
      setIsTyping(false);
    } catch (error) {
      return alert(
        "we are sorry for the inconvenience, something went wrong, try again later"
      );
    }
  }

  const handleSend = async (message, sender) => {
    countnumber = countnumber + 1;
    if (countnumber === 1) {
      setMessages([]);
    }

    const newMessage = {
      message,
      direction: "outgoing",
      sentTime: "just now",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    const userResponse = handleUserQueries(message);

    if (userResponse !== null) {
      // If there is a valid user response, send it as ChatGPT's response
      const chatGptResponse = {
        message: userResponse,
        sender: "ChatGPT",
        key: `chatGpt-${new Date().getTime()}`, // Assign a unique key
      };

      // Update messages with the user's query and ChatGPT's response
      setMessages([...newMessages, chatGptResponse]);

      // Set isTyping to false immediately
      setIsTyping(false);
    } else {
      // Otherwise, proceed with the GPT response
      if (newMessages.length >= 1) {
        // Include the user's question in the API call
        await processMessageToChatGPT(newMessages, newMessage);
      }
    }
  };

  const handleSendEntireChat = async (messages) => {
    const containsDesirePhrase = messages.some((message) => {
      const userMessage = message.message.toLowerCase();
      return userMessage?.includes("i want") || userMessage?.includes("i wish");
    });

    if (containsDesirePhrase) {
      const userData = {
        chatData: messages,
      };

      await storeChat(userData);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          zIndex: 200,
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            minHeight: "80px",
            background: "#334588",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "15px 15px 0px 0px",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={CloseIcon}
            sx={{
              position: "absolute",
              right: "5%",
              top: "20%",
              cursor: "pointer",
            }}
            onClick={() => {
              props.modelopen();
              handleSendEntireChat(messages);
            }}
          />
          <Typography sx={{ fontSize: "20px", color: "white" }}>
            Barter GPT
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "white" }}>
            I am your BXI Genie.
          </Typography>
        </Box>
        <Box
          sx={{
            height: "450px",
            overflow: "scroll",
          }}
          className="maindiv"
        >
          <MainContainer
            sx={{
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <ChatContainer>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator
                      content="typing"
                      sx={{
                        background: "#fff",
                        width: "20%",
                        borderRadius: "20px",
                      }}
                    />
                  ) : null
                }
              >
                {messages
                  ?.filter((e) => e?.sender != "system")
                  ?.map((message, i) => {
                    const isLastMessage = i === messages.length - 1;

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          marginTop: "10px",
                          marginBottom: isLastMessage ? "20px" : "0px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "15px",
                          }}
                        >
                          {message?.sender === "ChatGPT" ||
                          message?.sender === "name" ? (
                            <Box
                              component="img"
                              src={SenderLogo}
                              sx={{
                                height: "20px",
                                width: "20px",
                                marginTop: "10px",
                              }}
                            />
                          ) : null}
                        </Box>
                        <Typography
                          sx={{
                            width: "95%",
                            mx: "auto",
                            fontSize: "15px",
                            fontFamily: "poppins",
                            fontWeight: "400",
                            mb: 1.5,
                          }}
                        >
                          <Message key={i} model={message} />
                        </Typography>
                      </Box>
                    );
                  })}
              </MessageList>
              <MessageInput
                placeholder="Type your message here..."
                autoFocus={true}
                onSend={handleSend}
                sx={{
                  display: "flex",
                  bottom: "0%",
                  width: "95%",
                  mx: "auto",
                  p: 1,
                }}
              />
            </ChatContainer>
          </MainContainer>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalText}
            >
              Add : More Interest
            </Typography>
            <Box
              sx={{ ...modalText, cursor: "pointer" }}
              onClick={() => {
                handleClose();
              }}
            >
              X
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              mx: "auto",
              mt: 2,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography sx={modalText}>
              Name of Product/Voucher/media
            </Typography>
            <input
              fullWidth
              style={InputStyle}
              placeholder="Apple iphone 15(128GB storage , red)"
              {...register("productName")}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Box sx={adjInput}>
                <Typography sx={modalText}>Category</Typography>
                <select
                  style={{
                    ...InputStyle,
                    width: "100%",
                    borderRedius: "10px",
                    minWidth: "300px",
                  }}
                  fullWidth
                  placeholder="Select"
                  {...register("category")}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select Category</option>
                  <option value={"textile"}> textile </option>
                  <option value={"lifestyle"}> lifestyle </option>
                  <option value={"electronics"}> electronics </option>
                  <option value={"mobility"}> mobility </option>
                  <option value={"officesupply"}> officesupply </option>
                  <option value={"other"}> other </option>
                  <option value={"fmcg"}> fmcg </option>
                  <option value={"mediaonline"}> mediaonline </option>
                  <option value={"mediaoffline"}> mediaoffline </option>
                  <option value={"hotel"}> hotel </option>
                </select>
              </Box>
              <Box sx={adjInput}>
                <Typography sx={modalText}> Subcategory</Typography>
                <select
                  style={{
                    ...InputStyle,
                    width: "100%",
                    borderRedius: "10px",
                    minWidth: "300px",
                  }}
                  fullWidth
                  placeholder="Select"
                  {...register("subCategory")}
                >
                  {subCategoryData?.data?.map((subdata) => {
                    let subcategoryName = subdata.SubcategoryType;

                    if (!subcategoryName) {
                      if (subdata.HotelFeature) {
                        subcategoryName = subdata.HotelFeature;
                      } else if (subdata.SampleMobilityCategoryType) {
                        subcategoryName = subdata.SampleMobilityCategoryType;
                      } else if (subdata.OtherSub) {
                        subcategoryName = subdata.OtherSub;
                      } else if (subdata.SampleFmcgCategoryType) {
                        subcategoryName = subdata.SampleFmcgCategoryType;
                      } else if (subdata.Mediaonlinecategorysingle) {
                        subcategoryName = subdata.Mediaonlinecategorysingle;
                      } else if (subdata.Mediaofflinecategory) {
                        subcategoryName = subdata.Mediaofflinecategory;
                      } else if (subdata.SampleCategoryType) {
                        subcategoryName = subdata.SampleCategoryType;
                      }
                    }

                    return (
                      <option key={subdata._id} value={subcategoryName}>
                        {subcategoryName}
                      </option>
                    );
                  })}
                </select>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={adjInput}>
                <Typography sx={modalText}>Location </Typography>
                <input
                  style={{ ...InputStyle, minWidth: "280px" }}
                  fullWidth
                  placeholder="Maharashtra"
                  {...register("location")}
                />
              </Box>
              <Box sx={adjInput}>
                <Typography sx={modalText}>
                  By what date do you want this{" "}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "42px",
                    mt: "1%",
                    borderRadius: "0px",
                    border: "1px solid #E6E9EE",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    placeholder="70"
                    InputProps={{
                      disableUnderline: "true",
                      style: {
                        color: "#445FD2",
                        fontSize: "14px",
                        padding: "7px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "red",
                      },
                    }}
                    {...register("consumptionPeriod")}
                    sx={{
                      width: "80%",
                      height: "100%",
                      background: "#FFFFFF",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  />
                  <Select
                    sx={GW}
                    defaultValue={"Days"}
                    {...register("consumptionPeriodUnit")}
                  >
                    <MenuItem sx={MenuItems} value="Days">
                      Days
                    </MenuItem>
                    <MenuItem sx={MenuItems} value="Weeks">
                      Weeks
                    </MenuItem>
                    <MenuItem sx={MenuItems} value="Months">
                      Months
                    </MenuItem>
                    <MenuItem sx={MenuItems} value="Years">
                      Years
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 0,
              }}
            >
              <Box sx={adjInput}>
                <Typography sx={modalText}>Quanitity </Typography>
                <input
                  style={InputStyle}
                  fullWidth
                  placeholder="500"
                  {...register("qty")}
                />
              </Box>
              <Box sx={adjInput}>
                <Typography sx={modalText}>Nature of order </Typography>
                <select
                  style={{
                    ...InputStyle,
                    width: "100%",
                    borderRedius: "10px",
                    minWidth: "200px",
                  }}
                  fullWidth
                  placeholder="Select"
                  {...register("natureofOreder")}
                >
                  <option value={"oneTime"}> One Time </option>
                  <option value={"repetition"}> repetition </option>
                </select>
              </Box>
              <Box sx={adjInput}>
                <Typography sx={modalText}>Type </Typography>
                <select
                  style={{
                    ...InputStyle,
                    width: "100%",
                    borderRedius: "10px",
                    minWidth: "200px",
                  }}
                  fullWidth
                  placeholder="Select"
                  {...register("type")}
                >
                  <option value={"product"}> Product </option>
                  <option value={"voucher"}>Voucher </option>
                </select>
              </Box>
            </Box>
            <Typography sx={modalText}>
              {" "}
              Please share additional Remarks if any!
            </Typography>
            <input
              fullWidth
              style={InputStyle}
              placeholder="If any..."
              {...register("remarks")}
            />
            <Box
              sx={{
                height: "100%",
                width: "100%",
                maxWidth: "450px",
                mx: "auto",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: "1rem",
                alignContent: "center",
              }}
            >
              <Button
                sx={Styles.AddMemberBtn}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  ...Styles.AddMemberBtn,
                  background: "#445FD2",
                  color: "#fff",
                  "&:hover": {
                    border: "1px solid #445FD2",
                    color: "#445FD2",
                  },
                }}
                onClick={() => {
                  addWantList();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default BarterGPTModal;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};
const modalText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 10.27,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: -0.031,
};
const InputStyle = {
  height: "34px",
  width: "100%",
  // maxWidth: '350px',
  // minWidth: '350px',
  borderRadius: "4px",
  padding: "11px 363px 11px 16",
  border: "1.5px solid #E6E9EE",
  paddingLeft: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "22px",
  display: "flex",
  alignItems: "center",
  color: "#445FD2",
};

const adjInput = {
  height: "70px",
  display: "grid",
};
const GW = {
  width: "20%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#FFFFFF",
  height: "100%",
  color: "#6B7A99",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};
const MenuItems = {
  fontSize: "12px",
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};
