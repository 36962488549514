// import React from "react";
// const Bulkuploadpage = () => {
//   return (
//     <>
//       <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />
//     </>
//   );
// };

// export default Bulkuploadpage;
import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, Button } from "@mui/material";
import GoLeft from "../../../../assets/Images/CommonImages/GoLeft.png";
import BulkUploadImg from "../../../../assets/Images/CommonImages/bulkUploadImg.svg";
import { useNavigate } from "react-router-dom";
import MediaOfflineExcelSheet from "../../../../assets/ExelSheets/Media/OFF_Media_Category_-_NewsPaper.zip";
import MediaOfflineNewspaper from "../../../../assets/ExelSheets/Media_Offline_Final_-_NewsPaper.xlsx";
import downloadicon from "../../../../assets/download (1).svg";
import stepstobulkupload from "../../../../assets/Bulk_upload_steps.jpg";
import uploadicon from "../../../../assets/upload.svg";
import downlgrey from "../../../../assets/download (2).svg";
import filesave from "../../../../assets/file-save.svg";
import folderdwn from "../../../../assets/folder-download (1).svg";
import deleteicon from "../../../../assets/delete.svg";
import { useRef } from "react";
import Dowmwhite from "../../../../assets/download (3).svg";
import uploadwhite from "../../../../assets/upload (1).svg";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import avatar from "../../../../assets/Images/CommonImages/Companylogo (2).svg";
import CircularProgress from "@mui/material/CircularProgress";
import Avatargenerator from "../../../../components/AvatarGenerator.jsx";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser.js";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";

import {
  MediaBulkuploadproductToDB,
  loading,
} from "../../../../redux/action/Products/MediaBulkuploadproductToDB.js";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles({
  tableRow: {
    border: "1px solid rgba(224, 224, 224, 1)", // Change color as needed
  },
});

const MediaOfflineBulkUploadPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      // store the data in state
      const myForm = new FormData();
      myForm.append("file", file);
      setUpload(data);
      uploadExcelFile(myForm);
    };

    reader?.readAsBinaryString(file);
  };

  const uploadExcelFile = async (myForm) => {
    const { data } = await axios.post(
      "product/mediaProductBulkUploads?MediaType=Media-Peper",
      myForm,
      {
        headers: { "Content-type": "multipart/form-data" },
        withCredentials: true,
      }
    );

    setData(data);
    setLoading(true);
  };

  const UploadHandel = () => {
    dispatch(MediaBulkuploadproductToDB());
    toast.success("Data Successfully Added", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      navigate("/home/sellerhub");
    }, 5000);
  };

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
  } = useGetLoggedInUser();

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />

      {upload ? (
        <Grid container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "17px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              padding: "2rem",
            }}
            gap={2}
          >
            <Grid container>
              <Grid item lg={1} xl={1} md={1} sm={12} xs={12}>
                <Box
                  component={"img"}
                  src={GoLeft}
                  sx={{
                    width: "22px",
                    marginLeft: "-85%",
                    marginTop: "2%",
                    cursor: "pointer",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    navigate("/home");
                  }}
                />
              </Grid>

              <Grid item lg={8} xl={8} md={8} sm={12} xs={12}>
                <Typography
                  sx={{
                    color: "#929EAE",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "justify",
                  }}
                >
                  Product Details
                </Typography>
                <Typography
                  sx={{
                    color: "#929EAE",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "initial",
                  }}
                >
                  ( Data entered in the format template file is shown here.
                  Before clicking on upload, make sure to review that the
                  information entered is correct and complete to Go Live )
                </Typography>
              </Grid>

              {/* <Grid item lg={3} xl={3} md={3} sm={12} xs={12}>
                <Box>
                  <select
                    style={{
                      width: "100px",
                      height: "40px",
                      border: "1px solid #E6E9EE",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "100px",
                      padding: "0px 14px",
                      gap: "8px",
                      color: "gray",
                    }}
                  >
                    <option>All</option>
                    <option>All</option>
                  </select>
                </Box>
                  </Grid> */}
            </Grid>

            <Grid container>
              <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                <TableContainer
                  sx={{
                    minWidth: 650,
                    "& td": { border: 0 },
                    height: "500px",
                    maxHeight: "500px",
                    overflow: "scroll",
                    mt: "2%",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                          },
                        }}
                      >
                        <TableCell align="left" sx={tablehead}>
                          Serial Number
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product Name
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product SubCategory
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product Subtittle
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product Description
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Media Name
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Offerning Brandat
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Products Variantions
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product Quantity
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Discounted Price
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Price Per Unit
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Min Order Quantity
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Max Order Quantity
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          HSN
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          GST
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Timeline
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Location
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Unit
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Repetition
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Dimension Size
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Min Order Quantity Unit
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Min Order Quantity Timeline
                        </TableCell>

                        <TableCell align="left" sx={tablehead}>
                          Max Order Quantity Unit
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Max Order Quantity Timeline
                        </TableCell>

                        <TableCell align="left" sx={tablehead}>
                          Cost Price
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Reason Of Cost
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Currency Type
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Region
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          State
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          City
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Landmark
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Medianame
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Offerning Brandat
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Dimensions
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Upload Link
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          What support you would give to buyer
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Inspection Pass
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Log Report
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Videos
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Pictures
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Exhibition Certificate
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Other
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Calender
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Start Date
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          End Date
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Discounte Price Per Day
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Feature Name
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Feature Desc
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Legal In formation
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Position Or Edition Or Premium Offering Cost Of The Ad
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          What support you would give to buyer
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Other information buyer must knowOr Remarks
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Dimensions Of AdS Or Content Needed
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Content Upload Link
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product Images
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {loading === false ? (
                      <CircularProgress
                        disableShrink
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                          height: "10%",
                          width: "15%",
                          marginTop: "250px",
                          marginLeft: "550px",
                        }}
                      />
                    ) : (
                      <TableBody
                        sx={{
                          "& .MuiTableCell-root": {
                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                          },
                        }}
                      >
                        {data &&
                          data?.map((el, idx) => (
                            <TableRow key={idx} className={classes.tableRow}>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                  // key={idx}
                                >
                                  {idx + 1}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                  gap={2}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontFamily: "Kumbh Sans",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        color: "#1B212D",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,

                                        // width: "auto",
                                        // maxWidth: "120px",
                                        // display: "flex",
                                        // justifyContent: "space-between",
                                        // alignItems: "center",
                                        // alignContent: "center",
                                      }}
                                    >
                                      <Avatargenerator
                                        companyname={
                                          userData?.data?.companyName
                                        }
                                      />
                                      {el?.ProductName}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductSubCategoryName}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductSubtitle}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductDescription || "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.medianame}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.offerningbrandat || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions.length}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.MaxOrderQuantity}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.DiscountedPrice}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.PricePerUnit}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.MinOrderQuantity}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.MaxOrderQuantity}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.HSN}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.GST}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.Timeline ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.location ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.unit || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.repetition ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]?.dimensionSize ||
                                    "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]
                                    ?.minOrderQuantityunit || "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]
                                    ?.minOrderQuantitytimeline || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]
                                    ?.maxOrderQuantityunit || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductsVariantions[0]
                                    ?.minOrderQuantitytimeline || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.OtherCost[0]?.CostPrice || "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.OtherCost[0]?.ReasonOfCost}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.OtherCost[0]?.currencyType}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.GeographicalData?.region}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.GeographicalData?.state}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.GeographicalData?.city}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.GeographicalData?.landmark}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.medianame}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.offerningbrandat_1 || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.Dimensions}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.UploadLink || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductTechInfo
                                    ?.WhatSupportingYouWouldGiveToBuyer ||
                                    "Yes"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer
                                    ?.inspectionPass == "on"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer
                                    ?.LogReport == "on"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer
                                    ?.Videos == "on"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer
                                    ?.Pictures == "on"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer
                                    ?.ExhibitionCertificate == "on"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer
                                    ?.Other == "on"
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.calender.length}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.calender[0]?.startDate}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.calender[0]?.endDate}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.DiscountePricePerDay || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductFeatures[0]?.name}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductFeatures[0]?.description}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductTechInfo?.LegalInformation ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.PositionOrEditionOrPremiumOfferingCostOfTheAd ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.WhatSupportingYouWouldGiveToBuyer_1 ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductTechInfo
                                    ?.OtherInformationBuyerMustKnowOrRemarks[0] ||
                                    "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductTechInfo
                                    ?.DimensionsOfAdOrContentNeeded || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ContentUploadLink || "N/A"}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#1B212D",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {el?.ProductImages[0]?.url || "N/A"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                {loading === true ? (
                  <Button
                    variant="contained"
                    onClick={UploadHandel}
                    sx={filterbutton}
                  >
                    Upload Data
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "#fff",
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                marginTop: "20px",
                color: "#445FD2",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              10 STEPS TO BULK UPLOAD USING THE FORMAT TEMPLATE FILE
            </Typography>
            <Box
              sx={{
                width: "65%",
                height: "65%",
                // bgcolor: "red",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "225px",
              }}
            >
              <img
                src={stepstobulkupload} // Replace with the actual path to your image
                alt="Description of the image"
                style={{
                  width: "100%",
                  height: "100%",
                  marginTop: "10px",
                  objectFit: "cover", // This ensures the image covers the entire container
                }}
              />

              <Button
                variant="contained"
                color="primary"
                download="MediaExcel"
                target="_blank"
                href={MediaOfflineExcelSheet}
                sx={{
                  position: "absolute",
                  top: "275px",
                  left: "310px",
                  borderRadius: "5px",
                  bgcolor: "#1BC2F3",
                  width: "80px",
                  height: "20px",
                  fontSize: "9px",
                  padding: 0,
                  textTransform: "none",
                }}
                p={0}
              >
                Download Here
              </Button>
              <input
                type="file"
                id="file"
                ref={inputFile}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/home/imageupload")}
                sx={{
                  position: "absolute",
                  // bottom: "15px",
                  // left: "433px",
                  left: "870px",
                  top: "570px",
                  borderRadius: "5px",
                  bgcolor: "#1D1750",
                  width: "80px",
                  height: "20px",
                  fontSize: "8px",
                  textTransform: "none",
                }}
                p={0}
              >
                Upload Images
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => onButtonClick()}
                sx={{
                  position: "absolute",
                  bottom: "15px",
                  left: "433px",
                  borderRadius: "5px",
                  bgcolor: "#1D1750",
                  width: "80px",
                  height: "20px",
                  fontSize: "8px",
                  textTransform: "none",
                }}
                p={0}
              >
                Upload Here
              </Button>
            </Box>
          </Paper>
        </>
      )}

      {/* <Grid
        container
        sx={{
          borderRadius: "30px",
          display: "flex",
          justifyContent: "center",
          background: "#F3F6F9",
        }}
      ></Grid> */}
      {/* </Paper> */}
    </>
  );
};

export default MediaOfflineBulkUploadPage;
const buttonStyle = {
  backgroundColor: "#445FD2",
  fontFamily: "Poppins",
  fontSize: "12px",
  width: "300px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  textTransform: "none",
  color: "white",
  border: "none",
  borderRadius: "10px", // Adjust the value to control the roundness of the corners
  padding: "10px 20px",
  cursor: "pointer",
};
const mainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "27px",
  color: "#6B7A99",
};
const MetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "24px",
  textAlign: "justify",
  color: "#6B7A99",
  marginTop: "2rem",
};
const pointsStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "122.5%",
  display: "flex",
  alignItems: "center",
  color: "#6B7A99",
  marginTop: "1.5rem",
};
const downLoadText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 22,
  lineHeight: "18px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  textDecoration: "none",
  color: "#445FD2",
};
const noteText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "28px",
  display: "flex",
  alignItems: "center",
  marginTop: "3rem",
  color: "#6B7A99",
  width: "80%",
};
const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.2rem",
  color: "#929EAE",
};

const filterbutton = {
  // color: "#1B212D",
  // border: "1px solid #F5F5F5",
  // borderRadius: "10px",
  width: " 281px",
  height: "48px",
  ml: "auto",
  background: "#445FD2",
  borderRedius: "10px",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "none",
};

const Upload = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "18px",
  alignItems: "center",
  textAlign: "center",
  textDecoration: "none",
  color: "#445FD2",
  cursor: "pointer",
};
