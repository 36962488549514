import { Typography } from "@mui/material";
import React from "react";

const StyledTypography = (props) => {
  return (
    <Typography
      sx={{
        ...(props.textlength >= 2 ? { display: "flex" } : { display: "grid" }),
        ...props.style,
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
      }}
    >
      {props.text}
    </Typography>
  );
};

export default StyledTypography;
