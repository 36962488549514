import axios from "axios";
import { useQuery, useMutation } from "react-query";

export const useGetWantList = () => {
  return useQuery({
    queryKey: ["wantlist"],
    queryFn: async () => {
      return await axios.get(`wantlist/get_want_list`);
    },
  });
};

export const useAddToWantList = () => {
  return useMutation((data) => {
    return axios.post("wantlist/add_want_list", data, {
      withCredentials: true,
    });
  });
};

export const useRemoveWantList = () => {
  return useMutation((id) => {
    return axios.delete(`wantlist/delete_want_list/${id.id}`, {
      withCredentials: true,
    });
  });
};

export const useGetSubCategory = (category) => {
  return useQuery({
    queryKey: ["subcategory", category],
    queryFn: async () => {
      return await axios.get(`wantlist/get_sub_category?category=${category}`);
    },
  });
};
